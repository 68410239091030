import $service from '@/callApi/users'

export default {
    namespaced: true,
    state: {
        admins: [],
        pendingInvitations: [],
        notifications: [],

    },
    getters: {
        getAdmins: state => state.admins,
        getPendingInvitations: state => state.pendingInvitations,
        getNotifications: state => state.notifications,

    },
    mutations: {
        SET_ADMINS(state, val) {
            state.admins = val
        },
        SET_PENDING_INVITATIONS(state, val) {
            state.pendingInvitations = val
        },
        SET_NOTIFICATIONS(state, val) {
            state.notifications = val
        },
    },
    actions: {
        async loadMoreNotificationsAction({ commit }, url) {
            const response = await $service.getMoreNotificationService(url)
            commit('SET_NOTIFICATIONS', response.data.data)
            return response.data
        },

        async getNotificationsAction({ commit }) {
            const response = await $service.getNotificationService()
            commit('SET_NOTIFICATIONS', response.data.data)
            return response.data
        },

        async addNewNotificationAction({ commit }, payload) {
            const response = await $service.addNewNotificationService(payload)
            return response.data
        },

        async updateNotificationAction({ commit }, { payload, id }) {
            const response = await $service.updateNotificationService({
                payload,
                id,
            })
            return response.data
        },

        async deleteNotificationAction({ commit }, id) {
            return await $service.deleteNotificationService(id)
        },

        async sendNotificationAction({ commit }, id) {
            return await $service.sendNotificationService(id)
        },

        async getAdminsAction({ commit }) {
            const response = await $service.getAdminsService()
            commit('SET_ADMINS', response.data.data)
            return response.data
        },
        async changeAdminRoleAction({ commit }, {userId, roleId}) {
            const response = await $service.changeAdminRoleService( {userId, roleId} )
            return response.data
        },
        async deleteUserAction({ commit }, id) {
            const response = await $service.deleteUserService(id)
            return response.data
        },
        async getPendingInvitationsAction({ commit }) {
            const response = await $service.getPendingInvitationsService()
            commit('SET_PENDING_INVITATIONS', response.data.data)
            return response.data
        },
        async inviteAdminAction({ commit }, payload) {
            const response = await $service.inviteAdminService(payload)
            return response.data
        },
        async deleteInvitationAction({ commit }, token) {
            const response = await $service.deleteInvitationService(token)
            return response
        },
        async verifyInvitationAction({ commit }, token) {
            const response = await $service.verifyInvitationService(token)
            return response
        },
        async acceptInvitationAction({ commit }, payload) {
            const response = await $service.acceptInvitationService(payload)
            return response
        },

    },
}
