import { objectToFormData } from '@/@core/utils/utils'
import $http from '@/libs/axios'

export default {
  /* getOfferTypesService() {
    return $http.get('/admin/direct/offer/type')
  }, */
  getMorePointFocauxService(url) {
    return $http.get(`${url}`)
  },
  getPointFocalService() {
    return $http.get('/pointfocal')
  },
  getPointFocauxWithoutPaginationService() {
    return $http.get('/pointfocal/without/pagination')
  },
  pointFocalWalletOperationService({ id, payload }) {
    const data = objectToFormData(payload)
    return $http.post(`/pointfocal/${id}/wallet/operation`, data)
  },
  getOnePointFocalService(id) {
    return $http.get(`/pointfocal/wallet/${id}`)
  },

  addNewPointFocalService(payload) {
    const data = objectToFormData(payload)
    return $http.post('/pointfocal/store', data)
  },
  filterPointFocalService(payload) {
    const data = objectToFormData(payload)
    return $http.post('/pointfocal/all',data)
  },
  updateOnePointFocalService({ payload, id }) {
    const data = objectToFormData(payload)
    return $http.post(`/pointfocal/${id}/update`, data)
  },

  deleteOnePointFocalService(id) {
    return $http.delete(`/pointfocal/${id}/delete`)
  },

  /* getPlacementFeeService() {
    return $http.get('/admin/direct/orders/placement/fee')
  },

  updateOfferTypeService({ payload, id }) {
    const data = objectToFormData(payload)
    return $http.post(`/admin/direct/update/offer/type/${id}`, data)
  },
  updatePlacementFeeService({ payload, id }) {
    const data = objectToFormData(payload)
    return $http.post(`/admin/direct/orders/placement/fee/${id}`, data)
  },

  deleteOfferTypeService(id) {
    return $http.get(`/admin/direct/delete/offer/type/${id}`)
  }, */
}
