import { objectToFormData } from '@/@core/utils/utils'
import $http from '@/libs/axios'

export default {
  getCategoriesService() {
    return $http.get('/categories')
  },
  addCategoryService(payload) {
    const data = objectToFormData(payload)
    return $http.post('/admin/categories', data)
  },
  updateCategoryService(payload, id) {
    const data = objectToFormData(payload)
    return $http.post(`/admin/categories/${id}`, data)
  },
  deleteCategoryService(id) {
    return $http.delete(`/admin/categories/${id}`)
  },

}
