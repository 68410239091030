import { objectToFormData } from '@/@core/utils/utils'
// eslint-disable-next-line import/no-cycle
import $http from '@/libs/axios'

export default {
    getUnformedEmployeesService() {
        return $http.get('/employees/in/formation')
    },
    filterUnformedEmployeesService(payload) {
        const data = objectToFormData(payload)
        return $http.post('/employees/in/formation', data)
    },
    markEmployeeAsFormed({formationId, payload }) {
        const data = objectToFormData(payload)
        return $http.post(
            `/employees/formations/${formationId}/mark/is/formed`,
            data,
        )
    },
    getMoreOrderService(url) {
        return $http.get(`${url}`)
      },
      addServiceToFormationService({formationId, payload}){
        return $http.post(
            `/formations/${formationId}/add/services`,
            payload,
        )
      },
      /* const data = objectToFormData(payload)
        data.delete('services')
        payload.services.forEach(item => data.append('services[]', item))

        return $http.post(`/formations/${formationId}/add/services`, data) */
      deleteFormationServicesService({formationId, payload}){
        return $http.post(
            `/formations/${formationId}/remove/services`,
            payload,
        )
      },
      getEmployeeServices(employeeId) {
        return $http.get(`/employees/${employeeId}/services`)
      },
      getEmployeeDeleteServices(employeeId) {
        return $http.get(`/employees/${employeeId}/services?status=1`)
      },
}
