/* eslint-disable no-unused-vars */
// eslint-disable-next-line import/no-cycle
import $service from '@/callApi/suivi';

export default {
  namespaced: true,
  state: {
    suivis: [],

  },
  getters: {
    getSuivis: state => state.suivis,

  },
  mutations: {
     SET_SUIVIS_CLIENT(state, val) {
      state.suivis = val
    },
  },
  actions: {
    async markSuiviAction({ commit },  payload ) {
      const response = await $service.markSuiviService(payload)
      return response.data
    },
    async updateSuiviAction({ commit }, { suiviId, payload }) {
      const response = await $service.updateSuiviService({
        suiviId,
        payload,
      })
      return response.data
    },
    async getSuiviAction({ commit }, { payload }) {
      const response = await $service.getSuiviService({ payload })
      commit('SET_SUIVIS_CLIENT', response.data.data)
      return response.data
    },
    async filterSuiviAction({ commit }, payload) {
      const response = await $service.filterSuiviService({
        payload
      })
      return response.data
    },
    async getSuiviUnPublishedSuiviAction({ commit }, payload) {
      const response = await $service.getUnPublishedSuiviService({
        payload
      })
      return response.data
    },

    async sendSuiviAction({ commit }, payload) {
      const response = await $service.sendSuiviService({
        payload
      })
      return response.data
    },
     async getUnpublishedSuivisAction({ commit }, payload) {
      const response = await $service.getUnPublishedSuiviService({
        payload
      })
      return response.data
    },

      async sendRapportSuivisAction({ commit }) {
      const response = await $service.sendRapportSuivisService()
      return response.data
    },
  }
}