/* eslint-disable import/no-cycle */
import { objectToFormData } from '@/@core/utils/utils'
import $http from '@/libs/axios'

export default {
  loginService(payload) {
    const data = objectToFormData(payload)
    return $http.post('/auth/login', data)
  },
  changeProfileImageService(payload) {
    const data = objectToFormData(payload)
    return $http.post('/users/update/profile/image', data)
  },
  changeProProfileImageService(payload) {
    const data = objectToFormData(payload)
    return $http.post(`/professionals/${payload.id}/update/profile/image`, data)
  },
  passwordForgotService(payload) {
    const data = objectToFormData(payload)
    return $http.post('/auth/password/forgot', data)
  },
  resetPasswordService(payload) {
    const data = objectToFormData(payload)
    return $http.post('/auth/password/reset', data)
  },
  changePasswordService(payload) {
    const data = objectToFormData(payload)
    return $http.post('/auth/password/change', data)
  },
  changeProfileInfoService(payload) {
    const data = objectToFormData(payload)
    return $http.post('/users/update/profile', data)
  },
  changeProProfileInfoService(payload) {
    const data = objectToFormData(payload)
    return $http.post(`/professionals/${payload.id}/update/profile`, data)
  },
  logoutService() {
    return $http.get('auth/logout')
  },

  // Direct API CALL
  changeEmployeeProfileImageService(payload) {
    const data = objectToFormData(payload)
    return $http.post(`/employees/${payload.id}/update/profile/image`, data)
  },

  changeEmployeeProfileInfoService(payload) {
    const data = objectToFormData(payload)
    if ( payload['proof_files'] !== undefined) {
      data.delete('proof_files')
            payload.proof_files.forEach(item => data.append('proof_files[]', item))

    }

    console.log(payload.proof_files)
    return $http.post(`/employees/${payload.id}/update/profile`, data)
  },
}
