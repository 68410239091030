import { objectToFormData } from '@/@core/utils/utils'
// eslint-disable-next-line import/no-cycle
import $http from '@/libs/axios'

export default {
  getDateFilterFinanceStatisticsService() {
    return $http.get('/statistic/kpi/finance/date')
  },

  getDateFilterFinanceRangeStatisticsService(payload) {
    const data = objectToFormData(payload)
    return $http.post('/statistic/kpi/finance/date', data)
  },

  /* getPointFocauxStatisticsService() {
    return $http.get('/admin/statistics/number/of/employees/per/focal/point')
  }, */

  getMonthFilterFinanceStatisticsService() {
    return $http.get('/statistic/kpi/finance/month')
  },

  getMonthFilterFinanceRangeStatisticsService(payload) {
    const data = objectToFormData(payload)
    return $http.post('/statistic/kpi/finance/month', data)
  },

  getNumbersStatisticsService() {
    return $http.get('/statistic/kpi/for/admin')
  },
  getRCStatisticsService() {
    return $http.get('/statistic/kpi/for/RC')
  },
  getCAStatisticsService() {
    return $http.get('/statistic/kpi/for/CA')
  },
  getCCStatisticsService() {
    return $http.get('/statistic/kpi/for/CC')
  },
  getCAStatisticsByIdService(caId) {
    return $http.get(`/statistic/kpi/for/CA/${caId}`)
  },
  getRHStatisticsService() {
    return $http.get('/statistic/kpi/for/RH')
  },
  getARHStatisticsNumberService() {
    return $http.get('/statistic/kpi/for/ARH')
  },
  getTauxStatisticsService() {
    return $http.get('/statistic/rate/for/admin')
  },
  getTauxStatisticsRCService() {
    return $http.get('/statistic/rate/for/RC')
  },
  getTauxStatisticsCAService() {
    return $http.get('/statistic/rate/for/CA')
  },
  getTauxStatisticsCAByIdService(id) {
    return $http.get(`/statistic/rate/for/CA/${id}`)
  },
  getTauxStatisticsCCService() {
    return $http.get('/statistic/rate/for/CC')
  },
  getTauxStatisticsRHService() {
    return $http.get('/statistic/rate/for/RH')
  },
  getTauxStatisticsARHService() {
    return $http.get('/statistic/rate/for/ARH')
  },
  getTauxStatisticsARHByIdService(id) {
    return $http.get(`/statistic/rate/for/ARH/${id}`)
  },
  filterTauxStatisticsService(payload) {
    const data = objectToFormData(payload)
    return $http.post('/statistic/rate/for/admin', data)
  },
  filterTauxStatisticsRCService(payload) {
    const data = objectToFormData(payload)
    return $http.post('/statistic/rate/for/RC', data)
  },
  filterTauxStatisticsCAService(payload) {
    const data = objectToFormData(payload)
    return $http.post('/statistic/rate/for/CA', data)
  },
  filterTauxStatisticsCCService(payload) {
    const data = objectToFormData(payload)
    return $http.post('/statistic/rate/for/CC', data)
  },
  filterTauxStatisticsCAByIdService({id, payload}) {
    const data = objectToFormData(payload)
    return $http.post(`/statistic/rate/for/CA/${id}`, data)
  },
  filterTauxStatisticsRHService(payload) {
    const data = objectToFormData(payload)
    return $http.post('/statistic/rate/for/RH', data)
  },
  filterTauxStatisticsARHService(payload) {
    const data = objectToFormData(payload)
    return $http.post('/statistic/rate/for/ARH', data)
  },
  filterTauxStatisticsARHByIdService({id, payload}) {
    const data = objectToFormData(payload)
    return $http.post(`/statistic/rate/for/ARH/${id}`, data)
  },

  getNumbersRangeStatisticsService(payload) {
    const data = objectToFormData(payload)
    return $http.post('/statistic/kpi/for/admin', data)
  },
  getRCRangeStatisticsService(payload) {
    const data = objectToFormData(payload)
    return $http.post('/statistic/kpi/for/RC', data)
  },
  getCARangeStatisticsService(payload) {
    const data = objectToFormData(payload)
    return $http.post('/statistic/kpi/for/CA', data)
  },
  getCCRangeStatisticsService(payload) {
    const data = objectToFormData(payload)
    return $http.post('/statistic/kpi/for/CC', data)
  },
  getRHRangeStatisticsService(payload) {
    const data = objectToFormData(payload)
    return $http.post('/statistic/kpi/for/RH', data)
  },
  getARHRangeStatisticsService(payload) {
    const data = objectToFormData(payload)
    return $http.post('/statistic/kpi/for/ARH', data)
  },

  /* getProAmountService() {
    return $http.get('/admin/statistics/total/professional/amount/earned')
  },
  getSatisfiedOrderService() {
    return $http.get('/admin/statistics/satisfied/order')
  },
  getUnsatisfiedOrderService() {
    return $http.get('/admin/statistics/unsatisfied/order')
  },
  getTotalProAmountPerService() {
    return $http.get('/admin/statistics/total/professional/amount/earned/per/service')
  },
  getCommissionService() {
    return $http.get('/admin/statistics/commission')
  },
  getCustomerConversionService() {
    return $http.get('/admin/statistics/customer/conversion/rate')
  },
  getProAmountPerService() {
    return $http.get('/admin/statistics/total/professional/amount/earned/per/service')
  },
  getBusinessManagerStatisticsService(caId) {
    return $http.get(`/admin/statistics/for/CA/${caId}`)
  },
  getBusinessManagerStatisticsByRangeService({ caId, payload }) {
    const data = objectToFormData(payload)
    return $http.post(`/admin/statistics/for/CA/${caId}`, data)
  },
  getAdminOrderStatisticsByRangeService({ payload }) {
    const data = objectToFormData(payload)
    return $http.post('/admin/statistics/for/CC', data)
  },
  getBusinessDeveloperStatisticsService(bdpId) {
    return $http.get(`/admin/statistics/for/BDP/${bdpId}`)
  }, */
  getARHStatisticsService(arhId) {
    return $http.get(`/statistic/kpi/for/ARH/${arhId}`)
  },
  getARHStatisticsByRangeService({ arhId, payload }) {
    const data = objectToFormData(payload)
    return $http.post(`/statistic/kpi/for/ARH/${arhId}`, data)
  },
  getCAStatisticsByRangeByIdService({ caId, payload }) {
    const data = objectToFormData(payload)
    return $http.post(`/statistic/kpi/for/CA/${caId}`, data)
  },
  getBusinessDeveloperStatisticsByRangeService({ bdpId, payload }) {
    const data = objectToFormData(payload)
    return $http.post(`/admin/statistics/for/BDP/${bdpId}`, data)
  },
  getAdminOrderStatisticsService() {
    return $http.get('/admin/statistics/for/CC')
  },
  getPointFocalStatisticsService(pfId) {
    return $http.get(`/admin/statistics/for/PF/${pfId}`)
  },
  getPointFocalStatisticsByRangeService({ pfId, payload }) {
    const data = objectToFormData(payload)
    return $http.post(`/admin/statistics/for/PF/${pfId}`, data)
  },
}
