/* eslint-disable no-unused-vars */
// eslint-disable-next-line import/no-cycle
import $service from '@/callApi/services'

export default {
  namespaced: true,

  state: {
    services: [],
    mostRequestedServices: [],

    servicesWithoutPagination: [],

    recurringServicesWithoutPagination: [],

    mostRequestedRecurringServices: [],

    // Direct States
    recurringServices: [],
    serviceRecurringEmployees: [],

  },

  getters: {
    getServices: state => state.services,
    getServicesWithoutPagination: state => state.servicesWithoutPagination,
    getMostRequestedServices: state => state.mostRequestedServices,

    getMostRequestedRecurringServices: state => state.mostRequestedRecurringServices,

    // Direct Getters
    getRecurringServices: state => state.recurringServices,

    getRecurringServicesWithoutPagination: state => state.recurringServicesWithoutPagination,

    getServiceRecurringEmployees: state => state.serviceRecurringEmployees,
  },

  mutations: {

    SET_SERVICES(state, val) {
      state.services = val
    },

    SET_MOST_REQUESTED_SERVICES(state, val) {
      state.mostRequestedServices = val
    },

    SET_MOST_REQUESTED_RECURRING_SERVICES(state, val) {
      state.mostRequestedRecurringServices = val
    },

    SET_SERVICES_WITHOUT_PAGINATION(state, val) {
      state.servicesWithoutPagination = val
    },

    SET_RECURRING_SERVICES_WITHOUT_PAGINATION(state, val) {
      state.recurringServicesWithoutPagination = val
    },

    // Direct Mutations
    SET_RECURRING_SERVICES(state, val) {
      state.recurringServices = val
    },
    SET_SERVICE_RECURRING_EMPLOYEES(state, val) {
      state.serviceRecurringEmployees = val
    },
  },
  actions: {
    async filterServicesAction({ commit }, payload) {
      const response = await $service.filterServicesService(payload)
      commit('SET_SERVICES', response.data.data)
      return response.data
    },

    async filterRecurringServicesAction({ commit }, payload) {
      const response = await $service.filterRecurringServicesService(payload)
      commit('SET_RECURRING_SERVICES', response.data.data)
      return response.data
    },

    async getServicesWithoutPaginationAction({ commit }) {
      const response = await $service.getServicesWithoutPaginationService()
      commit('SET_SERVICES_WITHOUT_PAGINATION', response.data.data)
      return response.data
    },

    async getRecurringServicesWithoutPaginationAction({ commit }) {
      const response = await $service.getRecurringServicesWithoutPaginationService()
      commit('SET_RECURRING_SERVICES_WITHOUT_PAGINATION', response.data.data)
      return response.data
    },

    async getServicesAction({ commit }) {
      const response = await $service.getServices()
      commit('SET_SERVICES', response.data.data)
      return response.data
    },

    async loadMoreServicesAction({ commit }, url) {
      const response = await $service.getMoreServices(url)
      commit('SET_SERVICES', response.data.data)
      return response.data
    },

    async updateServiceAction({ commit }, { payload, id }) {
      const response = await $service.updateService(payload, id)
      return response.data
    },
    async getMostRequestedServicesAction({ commit }) {
      const response = await $service.getMostRequestedServices()
      commit('SET_MOST_REQUESTED_SERVICES', response.data.data)
      return response.data
    },
    async getMostRequestedRecurringServicesAction({ commit }) {
      const response = await $service.getMostRequestedRecurringServices()
      commit('SET_MOST_REQUESTED_RECURRING_SERVICES', [])
      commit('SET_MOST_REQUESTED_RECURRING_SERVICES', response.data.data)
      return response.data
    },
    async getServiceProfessionalsAction({ commit }, id) {
      const response = await $service.getServiceProfessionals(id)
      return response.data
    },

    async addServiceAction({ commit }, payload) {
      const response = await $service.addService(payload)
      return response.data
    },

    async deleteServiceAction({ commit }, id) {
      return await $service.deleteService(id)
    },

    async archiveServiceAction({ commit }, id) {
      return await $service.archiveService(id)
    },

    // Direct Actions
    async getRecurringServicesAction({ commit }) {
      const response = await $service.getRecurringServices()
      commit('SET_RECURRING_SERVICES', response.data.data)
      return response.data
    },

    async loadMoreRecurringServicesAction({ commit }, url) {
      const response = await $service.getMoreRecurringServices(url)
      commit('SET_RECURRING_SERVICES', response.data.data)
      return response.data
    },

    async addRecurringServiceAction({ commit }, payload) {
      const response = await $service.addRecurringService(payload)
      return response.data
    },

    async updateRecurringServiceAction({ commit }, { payload, id }) {
      const response = await $service.updateRecurringService(payload, id)
      return response.data
    },

    async deleteRecurringServiceAction({ commit }, id) {
      return await $service.deleteRecurringService(id)
    },

    async getServiceEmployeesAction({ commit }, id) {
      const response = await $service.getServiceEmployees(id)
      commit('SET_SERVICE_RECURRING_EMPLOYEES', response.data.data)
      return response.data
    },
    async getQuizAction({ commit }, serviceId) {
      const response = await $service.getQuizService(serviceId)
      return response.data
    },
    async addQuizAction({ commit }, payload) {
      const response = await $service.addQuizService(payload)
      return response.data
    },
    async deleteQuizAction({ commit }, id) {
      const response = await $service.deleteQuizService(id)
      return response.data
    },
    async updateQuizAction({ commit }, {id, payload}) {
      const response = await $service.updateQuizService({id, payload})
      return response.data
    },
  },
}
