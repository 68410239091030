import { objectToFormData } from '@/@core/utils/utils'
// eslint-disable-next-line import/no-cycle
import $http from '@/libs/axios'

export default {
   getAdminsService() {
    return $http.get('/users/admin')
  },

  changeAdminRoleService({ userId, roleId }) {
    return $http.get(`/users/${userId}/role/${roleId}/update`)
  }, 

  deleteUserService(id) {
    return $http.delete(`/users/${id}`)
  },

  inviteAdminService(payload) {
    const data = objectToFormData(payload)
    return $http.post('/invitation', data)
  },

  getPendingInvitationsService() {
    return $http.get('/invitation/pending')
  },

  verifyInvitationService(token) {
    return $http.get(`/invitation/${token}/verify`)
  },

  acceptInvitationService(payload) {
    const data = objectToFormData(payload)
    return $http.post(
      `/invitation/${localStorage.getItem('invitationToken')}/accept`,
      data,
    )
  },

  deleteInvitationService(token) {
    return $http.delete(`/invitation/${token}/delete`)
  },

  addNewNotificationService(payload) {
    const data = objectToFormData(payload)
    return $http.post('/notifications', data)
  },

  getNotificationService() {
    return $http.get('/notifications')
  },

  deleteNotificationService(id) {
    return $http.delete(`/notifications/${id}`)
  },

  updateNotificationService({ payload, id }) {
    console.log('payload', payload)
    const data = objectToFormData(payload)
    return $http.post(`/notifications/${id}`, data)
  },

  getMoreNotificationService(url) {
    return $http.get(`${url}`)
  },

  sendNotificationService(id) {
    return $http.get(`/notifications/${id}/send`)
  },
}
