import { objectToFormData } from '@/@core/utils/utils'
// eslint-disable-next-line import/no-cycle
import $http from '@/libs/axios'

export default {
  getPackagesService() {
    return $http.get('/admin/packages')
  },
  getOrdersService() {
    return $http.get('/orders')
  },
  getDirectOrdersActifService() {
    return $http.get('/admin/direct/orders/actifs')
  },
  filterDirectOrdersActifService(payload) {
    const data = objectToFormData(payload)
    return $http.post('/admin/direct/orders/actifs', data)
  },
  getMoreDirectOrdersActifService(url) {
    return $http.get(`${url}`)
  },

  getMoreBusinessOrderService(url) {
    return $http.get(`${url}`)
  },

  getMoreOrderService(url) {
    return $http.get(`${url}`)
  },

  getCanceledOrdersService() {
    return $http.get('/orders/canceled')
  },

  finishOrderService(id) {
    return $http.get(`/orders/${id}/finished`)
  },

  getOrderService(id) {
    return $http.get(`/orders/${id}`)
  },

  getOrderOffersService(id) {
    return $http.get(`/orders/${id}/offers`)
  },

  /*  totalBulkService(payload) {
    const data = objectToFormData(payload)
    return $http.post('/admin/direct/orders/total/bulk', data)
  }, */

  makeOfferService({ payload, orderId }) {
    return $http.post(`/orders/${orderId}/new/offer`, payload)
  },

  modifyOfferService({ updatePayload, offerId }) {
    const data = objectToFormData(updatePayload)
    return $http.post(`/offers/${offerId}/update`, data)
  },

  deleteOrderService(id) {
    return $http.delete(`/orders/${id}`)
  },

  deleteOrderOfferService(id) {
    return $http.delete(`/offers/${id}`)
  },

  getCustomerOrdersService(customerId) {
    return $http.get(`/admin/direct/customers/${customerId}/orders`)
  },

  // YLOMI DIRECT API ROUTES
  getDirectPackagesService() {
    return $http.get('/packages')
  },
  getBusinessOrdersService() {
    return $http.get('/package/business/recurring/orders')
  },
  getActifsBusinessEmployeesService(userId){
    return $http(`/users/${userId}/assigned/business/orders/employees/by`, {
      params: {
        status: 2,
        cnss: 1
        
      }
    })
  },

  advanceBusinessPaymentActionService({ payload, employeeId }) {
    const data = objectToFormData(payload)
    return $http.post(
      `/employees/${employeeId}/received/advance/salary`,
      data,
    )
  },


  /* getActifsBusinessEmployeesService(userId){
    return $http(`/users/${userId}/assigned/business/orders/employees/by?`, {
      params: {
        cnss: zzzz,
        status: $status
      }
    })
  }, */


  filterBusinessPackagesService(payload) {
    return $http.post('/package/business/recurring/orders', payload)
  },

  filterBusinessOrderInPackageService(id, payload) {
    return $http.post(`/package/business/recurring/orders/${id}/orders`, payload)
  },

  filterBusinessPackagesCommandesService(payload) {
    return $http.post('/package/business/recurring/orders/filter/by/status', payload)
  },

  getBusinessOrderService(id) {
    return $http.get(`/business/recurring/orders/${id}`)
  },

  getOrderInBusinessPackageService(id) {
    return $http.get(`/package/business/recurring/orders/${id}/orders`)
  },

  getOrderBusinessPackageByIdService(id) {
    return $http.get(`/package/business/recurring/orders/${id}`)
  },

  filterDirectOrderService(payload) {
    const data = objectToFormData(payload)
    return $http.post('/admin/direct/orders', data)
  },

  filterBusinessOrderService(payload) {
    return $http.post('/business/recurring/orders', payload)
  },

  filterPonctualOrderService(payload) {
    const data = objectToFormData(payload)
    return $http.post('/orders', data)
  },

  filterDirectOrdersWhoNeedRecommandationAction(payload) {
    const data = objectToFormData(payload)
    return $http.post('/admin/direct/orders/who/need/recommandations', data)
  },

  modifyDirectOrderService({ id, payload }) {
    const data = objectToFormData(payload)
    return $http.post(`/admin/direct/orders/${id}/update`, data)
  },

  modifyBusinessOrderService({ id, payload }) {
    const data = objectToFormData(payload)
    return $http.post(`/business/recurring/orders/${id}`, data)
  },

  getDirectOrderProposedEmployeesService(id) {
    return $http.get(`/admin/direct/orders/${id}/proposed/employees`)
  },

  getDirectOrdersSalaryPaymentsByOrderService(id) {
    return $http.get(`/admin/direct/orders/${id}/salaries/to/paid`)
  },

  getDirectOrdersUnformedEmployeesService() {
    return $http.get('/admin/direct/orders/accepted/employees/unformed')
  },
  filterDirectOrdersUnformedEmployeesService(payload) {
    const data = objectToFormData(payload)
    return $http.post('/admin/direct/orders/accepted/employees/unformed', data)
  },
  markEmployeeAsFormed({ formationId, payload }) {
    const data = objectToFormData(payload)
    return $http.post(
      `/admin/direct/employees/formations/${formationId}/mark/as/employee/is/formed`,
      data,
    )
  },

  deleteDirectOrderService(id) {
    return $http.delete(`/recurring/orders/${id}`)
  },

  deleteBusinessOrderService(id) {
    return $http.delete(`/business/recurring/orders/${id}`)
  },
  sendDeleteRequestService({ orderId, payload }) {
    const data = objectToFormData(payload)
    return $http.post(`/recurring/orders/${orderId}/request/deleted`, data)
  },

  cancelOrderService(paymentId) {
    return $http.delete(
      `/admin/direct/orders/cancel/salary/payment/${paymentId}`,
    )
  },

  resetDirectOrderService(id) {
    return $http.get(`/admin/direct/orders/${id}/reset`)
  },

  makeEmployeeRecommandationService(payload) {
    return $http.post('/recommandations/new', payload)
  },

  makeEmployeeResiliationService({ payload, orderId }) {
    return $http.post(
      `/business/recurring/orders/${orderId}/terminate/employees/contract`,
      payload,
    )
  },

  makeEmployeeAffectationService({ payload, id }) {
    return $http.post(
      `/business/recurring/orders/${id}/assign/employees`,
      payload,
    )
  },

  downloadPaymentService(payload) {
    return $http.post(
      '/business/recurring/orders/download/employees/payment/file',
      payload,
    )
  },

  makePropositionService({ payload, id }) {
    const data = objectToFormData(payload)
    return $http.post(`/admin/direct/orders/${id}/proposed/employee`, data)
  },

  deleteDirectOrderPropositionService({ directOrderId, employeeId }) {
    return $http.delete(
      `/admin/direct/orders/${directOrderId}/proposed/employees/${employeeId}`,
    )
  },

  terminateEmployeeContractService({ propositionId, payload }) {
    const data = objectToFormData(payload)
    return $http.post(
      `/propositions/${propositionId}/terminate/employee/contrat`,
      data,
    )
  },

  markDirectOrderAsFinishedService({ packageId, payload }) {
    const data = objectToFormData(payload)
    return $http.post(`/packages/${packageId}/terminate/contract`, data)
  },
  markUnterminateContractService(packageId) {
    return $http.get(`/packages/${packageId}/un/terminate/contract`)
  },
  getMoreBusinessPackagesService(url) {
    return $http.get(`${url}`)
  },

  makeBusinessOrderForAClientService(payload) {
    const data = objectToFormData(payload)
    data.delete('businessRecurringOrders')
   
    payload.businessRecurringOrders.forEach(function(element, index){
      const keys = Object.keys(element)

      keys.forEach(key => {
        data.append(`businessRecurringOrders[${index}][${key}]`, element[key])
      });
    })
    

   /*  payload.businessRecurringOrders.forEach(item => {
      data.append('businessRecurringOrders[]', JSON.stringify(item))      
    }); */
    console.log("DATA VENANT DU STORE", data)
    return $http.post(
      '/business/recurring/orders/new',
      data,
    )
  },

  assignAPackageToBusinessManagerService({ packageId, payload }) {
    const data = objectToFormData(payload)
    return $http.post(`/package/business/recurring/orders/${packageId}/assign/to/ca`, data)
  },

  autoAssignmentService({ packageId, payload }) {
    const data = objectToFormData(payload)
    return $http.post(`/package/business/recurring/orders/${packageId}/assign/to/ca`, data)
  },

  autoAssignmentRHService({packageId, payload}){
    const data = objectToFormData(payload)
    return $http.post(`/package/business/recurring/orders/${packageId}/assign/to/rh`, data)
  },

  makeEmployeeDeploymentService({ propositionId, payload }) {
    const data = objectToFormData(payload)
    return $http.post(
      `/propositions/${propositionId}/confirm/deployment`,
      data,
    )
  },
  makeBusinessEmployeeDeploymentService({ orderId, payload }) {
    return $http.post(
      `business/recurring/orders/${orderId}/confirm/employees/deployment`,
      payload,
    )
  },

  makeApplyCnssService({payload }) {
    return $http.post(
      `business/recurring/orders/apply/cnss`,
      payload,
    )
  },
  changeEmployeeDeploymentDateService({ propositionId, payload }) {
    const data = objectToFormData(payload)
    return $http.post(`/propositions/${propositionId}/change/deployment`, data)
  },

  changeBusinessEmployeeDeploymentDateService({ propositionId, payload }) {
    const data = objectToFormData(payload)
    return $http.post(
      `/employees/assigned/${propositionId}/change/deployment/date`,
      data,
    )
  },
  sendFeedbackService({ orderId, payload }) {
    const data = objectToFormData(payload)
    return $http.post(`/admin/direct/orders/${orderId}/send/feedback`, data)
  },

  getRecommendedEmployeesService(orderId) {
    return $http.post('/recommandations', orderId)
  },

  getAffectedEmployeesService(orderId) {
    return $http.get(`/business/recurring/orders/${orderId}/employees/assigned`)
  },

  getActifEmployeesService(orderId) {
    return $http.get(`/business/recurring/orders/${orderId}/employees/assigned/by?status=2`)
  },

  modifyRecommendationService({ employeeRecommendationId, payload }) {
    const data = objectToFormData(payload)
    return $http.post(`/recommandations/${employeeRecommendationId}`, data)
  },

  modifyAffectationService({ employeeAffectedId, payload }) {
    const data = objectToFormData(payload)
    return $http.post(`/employees/assigned/${employeeAffectedId}`, data)
  },

  getTerminatedEmployeesService(orderId) {
    return $http.get(
      `/admin/direct/orders/${orderId}/list/of/former/employees`,
    )
  },

  modifyPropositionService({ payload, id, proposedEmployeeId }) {
    const data = objectToFormData(payload)
    return $http.post(
      `/admin/direct/orders/${id}/proposed/employees/${proposedEmployeeId}/update`,
      data,
    )
  },

  assignAnBusinessOrderToCaService({ orderId, payload }) {
    const data = objectToFormData(payload)
    return $http.post(
      `/business/recurring/orders/${orderId}/assign/to/ca`,
      data,
    )
  },
  assignAnBusinessOrderToARHService({ orderId, payload }) {
    const data = objectToFormData(payload)
    return $http.post(`/package/business/recurring/orders/${orderId}/assign/to/rh`, data)
  },

  /* ManagerService({ orderId, payload }) {
    const data = objectToFormData(payload)
    return $http.post(`/admin/direct/orders/${orderId}/assign`, data)
  },

  getDirectOrdersAssignedToBusinessManagerService(businessManagerId) {
    return $http.get(`/admin/direct/orders/assigned/to/${businessManagerId}`)
  }, */
  getDirectOrdersSalaryPaymentsService() {
    return $http.get('/payments')
  },
  filterDirectOrdersSalaryPaymentsService(payload) {
    const data = objectToFormData(payload)
    return $http.post('/payments', data)
  },

  filterBusinessOrdersSalaryPaymentsService(payload) {
    const data = objectToFormData(payload)
    return $http.post('/business/recurring/orders/salarys', data)
  },

  getBusinessOrdersSalaryPaymentsService() {
    return $http.get('/business/recurring/orders/salarys')
  },

  getDirectOrdersSalaryEmployeesService() {
    return $http.get('/payments/history')
  },
  filterDirectOrdersSalaryEmployeesService(payload) {
    const data = objectToFormData(payload)
    return $http.post('/payments/history', data)
  },

  /* getNotCompletedDirectOrdersService() {
    return $http.get('/admin/direct/orders/failed')
  },

  takeOverService(orderId) {
    return $http.get(`/admin/direct/orders/failed/${orderId}/consider`)
  },

  deleteFailedDirectOrderService(directOrderId) {
    return $http.delete(`/admin/direct/orders/failed/${directOrderId}`)
  },
 */
  archiveDirectOrderService({ directOrderId, payload }) {
    const data = objectToFormData(payload)

    return $http.post(`/recurring/orders/${directOrderId}/archive`, data)
  },

  archiveBusinessOrderService({ directOrderId, payload }) {
    const data = objectToFormData(payload)

    return $http.post(
      `business/recurring/orders/${directOrderId}/archive`,
      data,
    )
  },

  updateDirectOrderArchivingService({ directOrderId, payload }) {
    const data = objectToFormData(payload)
    return $http.post(
      `/recurring/orders/${directOrderId}/update/archiving/reason`,
      data,
    )
  },

  updateBusinessOrderArchivingService({ directOrderId, payload }) {
    const data = objectToFormData(payload)
    return $http.post(
      `/business/recurring/orders/${directOrderId}/update/archiving/reason`,
      data,
    )
  },

  getArchivedDirectOrdersService() {
    return $http.get('/recurring/orders/archived')
  },
  filterArchivedDirectOrdersService(payload) {
    const data = objectToFormData(payload)
    return $http.post('/recurring/orders/archived', data)
  },

  unarchiveDirectOrderService(directOrderId) {
    return $http.get(`/recurring/orders/${directOrderId}/unarchive`)
  },

  unarchiveBusinessOrderService(directOrderId) {
    return $http.post(`/business/recurring/orders/${directOrderId}/un/archive`)
  },

  getArchivedBusinessOrdersService() {
    return $http.get('/business/recurring/orders/archived')
  },

  filterArchivedBusinessOrdersService(payload) {
    const data = objectToFormData(payload)
    return $http.post('/business/recurring/orders/archived', data)
  },

  /* getDirectOrdersWhoNeedRecommandation() {
    return $http.get('/admin/direct/orders/who/need/recommandations')
  }, */

  loadMoreDirectOrdersWhoNeedRecommandation(url) {
    return $http.get(`${url}`)
  },
  getSalaryPayementDetailsServices({ packageId, payload }) {
    const data = objectToFormData(payload)
    return $http.post(`/packages/${packageId}/details/salary/payment`, data)
  },

  getBusinessSalaryPayementDetailsServices(payload) {
    const data = objectToFormData(payload)
    return $http.post('/business/recurring/orders/details/salary', data)
  },
  confirmSalaryCumulPaymentServices({ packageId, payload }) {
    const data = objectToFormData(payload)
    return $http.post(`/packages/${packageId}/confirm/payments`, data)
  },
  cancelCumulPaymentServices({ packageId, payload }) {
    const data = objectToFormData(payload)
    return $http.post(`/packages/${packageId}/cancel/payments`, data)
  },
  confirmSingularSalaryPaymentServices(paymentId) {
    return $http.get(`/payments/${paymentId}/confirm`)
  },
  cancelSingularSalaryPaymentServices(paymentId) {
    return $http.get(`/payments/${paymentId}/cancel`)
  },
  cancelUnpaidPaymentServices(paymentId) {
    return $http.delete(`/business/recurring/orders/payments/${paymentId}/cancel`)
  },
  storeCommentServices(payload) {
    const data = objectToFormData(payload)
    return $http.post('/comments', data)
  },
  getCommentsServices(orderId) {
    return $http.get(`/comments/recurring/order/${orderId}`)
  },
  deleteCommentServices(commentId) {
    return $http.delete(`/comments/${commentId}`)
  },
  updateCommentServices({ commentId, payload }) {
    const data = objectToFormData(payload)
    return $http.post(`/comments/${commentId}`, data)
  },
  reactiveEmployeeServices({ propositionId, payload }) {
    return $http.post(
      `/propositions/${propositionId}/un/terminate/employee/contrat`, payload
    )
  },
  transfertOrderTobusinessService(orderId){
    return $http.post(`/recurring/orders/${orderId}/to/business/recurring/order`)
  },
  getTransactionsServices() {
    return $http.get('/transactions')
  },
  filterTransactionsServices(payload) {
    const data = objectToFormData(payload)
    return $http.post('/transactions', data)
  },
  getMoreTransactionsService(url) {
    return $http.get(`${url}`)
  },

  getClientByPortefeuilleServices() {
    return $http.get('/packages/automatic/payment')
  },
  filterClientByPortefeuilleServices(payload) {
    const data = objectToFormData(payload)
    return $http.post('/packages/automatic/payment', data)
  },
  getMoreClientByPortefeuilleService(url) {
    return $http.get(`${url}`)
  },
  getCustomersMeetServices() {
    return $http.get('/propositions/interviewed')
  },
  filterCustomersMeetServices(payload) {
    const data = objectToFormData(payload)
    return $http.post('/propositions/interviewed', data)
  },
  getMoreCustomersMeetService(url) {
    return $http.get(`${url}`)
  },
  getMoreActivityService(url) {
    return $http.get(`${url}`)
  },
  getActivityServices() {
    return $http.get('/utils/logs')
  },
  meetValidationServices(meetId) {
    return $http.get(`/propositions/${meetId}/interview/validate`)
  },
  changeMeetServices({ meetId, payload }) {
    return $http.post(`/propositions/${meetId}/interview/change/date`, payload)
  },
  automaticPaymentValidationServices(packageId) {
    return $http.get(`/packages/${packageId}/valid/automatic/pay`)
  },
  desactivateAutomaticPaymentServices(packageId) {
    return $http.get(`/packages/${packageId}/desactive/automatic/pay`)
  },
  getUnpaidOrdersServices(packageId) {
    return $http.get(`/packages/${packageId}/unpaid/recurring/orders`)
  },
  filterCnssServices(payload) {
    const data = objectToFormData(payload)
    return $http.post('/payments/filter/employees/cnss', data)
  },
  businessEmployeeSalaryUnpaidServices() {
    return $http.get('/business/recurring/orders/un/paid/salary')
  },

  businessUnpaidEmployeesServices(userId) {
    return $http.get(`users/${userId}/un/paid/business/salary`)
  },
  markSalaryPaymentServices(payload) {
    return $http.post('/business/recurring/orders/confirm/salarys', payload)
  },
  employeeResiliationServices(payload) {
    return $http.post(
      '/business/recurring/orders/21/terminate/employee/25/contract',
      payload,
    )
  },
  confirmOfferPaymentServices({
    orderId, offerId, full, amount,
  }) {
    return $http.get(
      `/orders/${orderId}/offers/${offerId}/confirm/payment?full=${full}&amount=${amount}&isConfirmation=true`,
    )
  },
}
