import { objectToFormData } from '@/@core/utils/utils'
import $axios from '@/libs/axios'

export default {
  createCategoryService(payload) {
    const data = objectToFormData(payload)
    return $axios.post('/blog/categories', data)
  },
  updateCategoryService(payload, id) {
    const data = objectToFormData(payload)
    return $axios.post(`/blog/categories/${id}/update`, data)
  },
  deleteCategoryService(id) {
    return $axios.get(`/blog/categories/${id}/delete`)
  },
  getAllCategoryService() {
    return $axios.get('/blog/categories')
  },
  createArticleService(payload) {
    const data = objectToFormData(payload)
    return $axios.post('/blog/posts', data)
  },
  getAllArticleService() {
    return $axios.get('/blog/posts')
  },
  loadMoreArticleService(url) {
    return $axios.get(`${url}`)
  },
  getArticleDetailService(id) {
    return $axios.get(`/blog/posts/${id}`)
  },
  validateArticleService(id) {
    return $axios.get(`/blog/posts/${id}/validate`)
  },
  publishArticleService(id) {
    return $axios.get(`/blog/posts/${id}/publish`)
  },
  deleteArticleService(id) {
    return $axios.delete(`/blog/posts/${id}`)
  },
  updateArticleService(payload, id) {
    const data = objectToFormData(payload)
    return $axios.post(`/blog/posts/${id}`, data)
  },
  rejectArticleService(payload, id) {
    const data = objectToFormData(payload)
    return $axios.post(`/blog/posts/${id}/reject`, data)
  },
}
