/* eslint-disable no-unused-vars */
// eslint-disable-next-line import/no-cycle
import $service from '@/callApi/fomation'

export default {
    namespaced: true,
    state: {
        unformedEmployees: [],
    },
    getters: {
        getUnformedEmployees: state => state.unformedEmployees,
    },
    mutations: {
        SET_UNFORMED_EMPLOYEES(state, val) {
            state.unformedEmployees = val
        },
    },
    actions: {
        async getUnformedEmployeesAction({ commit }) {

            const response = await $service.getUnformedEmployeesService()
            commit('SET_UNFORMED_EMPLOYEES', response.data.data)
            return response.data
        },

        async filterUnformedEmployeesAction({ commit }, payload) {
            const response = await $service.filterUnformedEmployeesService(payload)
            commit('SET_UNFORMED_EMPLOYEES', response.data.data)
            return response.data
        },

        async markEmployeeAsFormedAction({ commit }, { formationId, payload }) {
            const response = await $service.markEmployeeAsFormed({
                formationId,
                payload,
            })
            return response.data
        },

        async loadMoreUnformedEmployeesAction({ commit }, url) {
            const response = await $service.getMoreOrderService(url)
            commit('SET_UNFORMED_EMPLOYEES', response.data.data)
            return response.data
        },

        async addServiceToFormationAction({ commit }, {formationId, payload}) {
            const response = await $service.addServiceToFormationService({formationId, payload})
            return response.data
        },
        async deleteFormationServicesAction({ commit }, {formationId, payload}) {
            const response = await $service.deleteFormationServicesService({formationId, payload})
            return response.data
        },

        async getEmployeeServicesAction({ commit }, employeeId) {
            const response = await $service.getEmployeeServices(employeeId)
            return response.data
        },
        async getEmployeeDeleteServicesAction({ commit }, employeeId) {
            const response = await $service.getEmployeeDeleteServices(employeeId)
            return response.data
        },
    },
}
