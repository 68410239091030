/* eslint-disable no-unused-vars */
// eslint-disable-next-line import/no-cycle
import $service from '@/callApi/statistics'

export default {
  namespaced: true,
  state: {
    commissions: 0,
    proAmount: 0,
    customerConversion: 0,
    satisfiedOrder: 0,
    unsatisfiedOrder: 0,
    professionalsAmountPerServices: [],
    pointFocauxStat: [],
    statistics: null,
    dateFinanceStatistics: null,
    numbersStatistics: null,
    tauxStatistics: null,
    rhStatistics: null,
    monthFinanceStatistics: null,
  },
  getters: {
    getProAmount: state => state.proAmount,
    getSatisfiedOrder: state => state.satisfiedOrder,
    getUnsatisfiedOrder: state => state.unsatisfiedOrder,
    getCommissins: state => state.commissions,
    getCustomerConversion: state => state.customerConversion,
    getProfessionalsAmountPerServices: state => state.professionalsAmountPerServices,
    getStatistics: state => state.statistics,
    getDateFinanceStatistics: state => state.dateFinanceStatistics,
    getMonthFinanceStatistics: state => state.monthFinanceStatistics,
    getNumbersStatistics: state => state.numbersStatistics,
    getTauxStatistics: state => state.tauxStatistics,
    getRHStatistics: state => state.rhStatistics,
    getPointFocauxStat: state => state.pointFocauxStat,
  },

  mutations: {
    SET_PRO_AMOUNT(state, val) {
      state.proAmount = val
    },
    SET_CUSTOMER_CONVERSION(state, val) {
      state.customerConversion = val
    },
    SET_SATISFIED_ORDER(state, val) {
      state.satisfiedOrder = val
    },
    SET_UNSATISFIED_ORDER(state, val) {
      state.unsatisfiedOrder = val
    },
    SET_COMMISSIONS(state, val) {
      state.commissions = val
    },
    SET_PROFESSIONALS_AMOUNT_PER_SERVICE(state, val) {
      state.professionalsAmountPerServices = val
    },
    SET_DATE_FINANCE_STATISTICS(state, val) {
      state.dateFinanceStatistics = val
    },
    SET_NUMBERS_STATISTICS(state, val) {
      state.numbersStatistics = val
    },
    SET_TAUX_STATISTICS(state, val) {
      state.tauxStatistics = val
    },
    SET_RH_STATISTICS(state, val) {
      state.rhStatistics = val
    },
    SET_POINT_FOCAUX_STAT(state, val) {
      state.pointFocauxStat = val
    },

    SET_MONTH_FINANCE_STATISTICS(state, val) {
      state.monthFinanceStatistics = val
    },
  },
  actions: {
    async getDateFinanceStatisticsAction({ commit }) {
      const response = await $service.getDateFilterFinanceStatisticsService()
      commit('SET_DATE_FINANCE_STATISTICS', response.data.data)
      return response.data
    },
    async getPointFocauxStatisticsAction({ commit }) {
      const response = await $service.getPointFocauxStatisticsService()
      commit('SET_POINT_FOCAUX_STAT', response.data.data)
      return response.data
    },

    async getDateFinanceRangeStatisticsAction({ commit }, payload) {
      const response = await $service.getDateFilterFinanceRangeStatisticsService(payload)
      commit('SET_DATE_FINANCE_STATISTICS', response.data.data)
      return response.data
    },

    async getMonthFinanceStatisticsAction({ commit }) {
      const response = await $service.getMonthFilterFinanceStatisticsService()
      commit('SET_MONTH_FINANCE_STATISTICS', response.data.data)
      return response.data
    },

    async getMonthFinanceRangeStatisticsAction({ commit }, payload) {
      const response = await $service.getMonthFilterFinanceRangeStatisticsService(payload)
      commit('SET_MONTH_FINANCE_STATISTICS', response.data.data)
      return response.data
    },
    async getNumbersStatisticsAction({ commit }) {
      const response = await $service.getNumbersStatisticsService()
      commit('SET_NUMBERS_STATISTICS', response.data.data)
      return response.data
    },
    async getRCStatisticsAction({ commit }) {
      const response = await $service.getRCStatisticsService()
      commit('SET_NUMBERS_STATISTICS', response.data.data)
      return response.data
    },
    async getCAStatisticsAction({ commit }) {
      const response = await $service.getCAStatisticsService()
      commit('SET_NUMBERS_STATISTICS', response.data.data)
      return response.data
    },
    async getCCStatisticsAction({ commit }) {
      const response = await $service.getCCStatisticsService()
      commit('SET_NUMBERS_STATISTICS', response.data.data)
      return response.data
    },
    async getCAStatisticsByIdAction({ commit }, caId) {
      const response = await $service.getCAStatisticsByIdService(caId)
      return response.data
    },
    async getCAStatisticsByRangeByIdAction({ commit }, { caId, payload }) {
      const response = await $service.getCAStatisticsByRangeByIdService({ caId, payload })
      return response.data
    },
    async getRHStatisticsAction({ commit }) {
      const response = await $service.getRHStatisticsService()
      commit('SET_NUMBERS_STATISTICS', response.data.data)
      return response.data
    },
    async getARHStatisticsNumberAction({ commit }) {
      const response = await $service.getARHStatisticsNumberService()
      commit('SET_NUMBERS_STATISTICS', response.data.data)
      return response.data
    },
    async getTauxStatisticsAction({ commit }) {
      const response = await $service.getTauxStatisticsService()
      commit('SET_TAUX_STATISTICS', response.data.data)
      return response.data
    },
    async getTauxStatisticsRCAction({ commit }) {
      const response = await $service.getTauxStatisticsRCService()
      commit('SET_TAUX_STATISTICS', response.data.data)
      return response.data
    },
    async getTauxStatisticsCAAction({ commit }) {
      const response = await $service.getTauxStatisticsCAService()
      commit('SET_TAUX_STATISTICS', response.data.data)
      return response.data
    },
     async getTauxStatisticsCAByIdAction({ commit }, id) {
      const response = await $service.getTauxStatisticsCAByIdService(id)
      commit('SET_TAUX_STATISTICS', response.data.data)
      return response.data
    },
    async getTauxStatisticsCCAction({ commit }) {
      const response = await $service.getTauxStatisticsCCService()
      commit('SET_TAUX_STATISTICS', response.data.data)
      return response.data
    },
    async getTauxStatisticsRHAction({ commit }) {
      const response = await $service.getTauxStatisticsRHService()
      commit('SET_TAUX_STATISTICS', response.data.data)
      return response.data
    },
    async getTauxStatisticsARHAction({ commit }) {
      const response = await $service.getTauxStatisticsARHService()
      commit('SET_TAUX_STATISTICS', response.data.data)
      return response.data
    },
    async getTauxStatisticsARHByIdAction({ commit }, id) {
      const response = await $service.getTauxStatisticsARHByIdService(id)
      commit('SET_TAUX_STATISTICS', response.data.data)
      return response.data
    },
    async filterTauxStatisticsAction({ commit }, payload) {
      const response = await $service.filterTauxStatisticsService(payload)
      commit('SET_TAUX_STATISTICS', response.data.data)
      return response.data
    },
    async filterTauxStatisticsRCAction({ commit }, payload) {
      const response = await $service.filterTauxStatisticsRCService(payload)
      commit('SET_TAUX_STATISTICS', response.data.data)
      return response.data
    },
    async filterTauxStatisticsCAAction({ commit }, payload) {
      const response = await $service.filterTauxStatisticsCAService(payload)
      commit('SET_TAUX_STATISTICS', response.data.data)
      return response.data
    },
    async filterTauxStatisticsCCAction({ commit }, payload) {
      const response = await $service.filterTauxStatisticsCCService(payload)
      commit('SET_TAUX_STATISTICS', response.data.data)
      return response.data
    },
    async filterTauxStatisticsCAByIdAction({ commit }, {id, payload}) {
      const response = await $service.filterTauxStatisticsCAByIdService({id, payload})
      commit('SET_TAUX_STATISTICS', response.data.data)
      return response.data
    },
    async filterTauxStatisticsRHAction({ commit }, payload) {
      const response = await $service.filterTauxStatisticsRHService(payload)
      commit('SET_TAUX_STATISTICS', response.data.data)
      return response.data
    },
    async filterTauxStatisticsARHAction({ commit }, payload) {
      const response = await $service.filterTauxStatisticsARHService(payload)
      commit('SET_TAUX_STATISTICS', response.data.data)
      return response.data
    },
    async filterTauxStatisticsARHByIdAction({ commit }, {id, payload}) {
      const response = await $service.filterTauxStatisticsARHByIdService({id, payload})
      commit('SET_TAUX_STATISTICS', response.data.data)
      return response.data
    },
    async getNumbersRangeStatisticsAction({ commit }, payload) {
      const response = await $service.getNumbersRangeStatisticsService(payload)
      commit('SET_NUMBERS_STATISTICS', response.data.data)
      return response.data
    },

    async getRCRangeStatisticsAction({ commit }, payload) {
      const response = await $service.getRCRangeStatisticsService(payload)
      commit('SET_NUMBERS_STATISTICS', response.data.data)
      return response.data
    },
    async getCARangeStatisticsAction({ commit }, payload) {
      const response = await $service.getCARangeStatisticsService(payload)
      commit('SET_NUMBERS_STATISTICS', response.data.data)
      return response.data
    },
    async getCCRangeStatisticsAction({ commit }, payload) {
      const response = await $service.getCCRangeStatisticsService(payload)
      commit('SET_NUMBERS_STATISTICS', response.data.data)
      return response.data
    },

    async getRHRangeStatisticsAction({ commit }, payload) {
      const response = await $service.getRHRangeStatisticsService(payload)
      commit('SET_NUMBERS_STATISTICS', response.data.data)
      return response.data
    },
    async getARHRangeStatisticsAction({ commit }, payload) {
      const response = await $service.getARHRangeStatisticsService(payload)
      commit('SET_NUMBERS_STATISTICS', response.data.data)
      return response.data
    },

    async getCommissionAction({ commit }) {
      const response = await $service.getCommissionService()
      commit('SET_COMMISSIONS', response.data.data)
      return response.data
    },
    async getProAmountPerServiceAction({ commit }) {
      const response = await $service.getProAmountPerService()
      commit('SET_PROFESSIONALS_AMOUNT_PER_SERVICE', response.data.data)
      return response.data
    },

    async getCustomerConversionAction({ commit }) {
      const response = await $service.getCustomerConversionService()
      commit('SET_CUSTOMER_CONVERSION', response.data.data)
      return response.data
    },

    async getProAmountAction({ commit }) {
      const response = await $service.getProAmountService()
      commit('SET_PRO_AMOUNT', response.data.data)
      return response.data
    },

    async getSatisfiedOrderAction({ commit }) {
      const response = await $service.getSatisfiedOrderService()
      commit('SET_SATISFIED_ORDER', response.data.data)
      return response.data
    },

    async getUnsatisfiedOrderAction({ commit }) {
      const response = await $service.getUnsatisfiedOrderService()
      commit('SET_UNSATISFIED_ORDER', response.data.data)
      return response.data
    },

    async getBusinessManagerStatisticsAction({ commit }, caId) {
      const response = await $service.getBusinessManagerStatisticsService(caId)
      return response.data
    },

    async getAdminOrderStatisticsAction({ commit }) {
      const response = await $service.getAdminOrderStatisticsService()
      return response.data
    },

    async getBusinessManagerStatisticsByRangeAction({ commit }, { caId, payload }) {
      const response = await $service.getBusinessManagerStatisticsByRangeService({ caId, payload })
      return response.data
    },

    async getAdminOrderStatisticsByRangeAction({ commit }, { payload }) {
      const response = await $service.getAdminOrderStatisticsByRangeService({ payload })
      return response.data
    },

    async getBusinessDeveloperStatisticsAction({ commit }, bdpId) {
      const response = await $service.getBusinessDeveloperStatisticsService(bdpId)
      return response.data
    },
    async getARHStatisticsAction({ commit }, arhId) {
      const response = await $service.getARHStatisticsService(arhId)
      return response.data
    },
    async getARHStatisticsAction({ commit }, arhId) {
      const response = await $service.getARHStatisticsService(arhId)
      return response.data
    },
    async getARHStatisticsByRangeAction({ commit }, { arhId, payload }) {
      const response = await $service.getARHStatisticsByRangeService({ arhId, payload })
      return response.data
    },

    async getPointFocalStatisticsAction({ commit }, pfId) {
      const response = await $service.getPointFocalStatisticsService(pfId)
      return response.data
    },
    async getPointFocalStatisticsByRangeAction({ commit }, { pfId, payload }) {
      const response = await $service.getPointFocalStatisticsByRangeService({ pfId, payload })
      return response.data
    },
  },
}
