import $service from '@/callApi/offerType'

export default {
  namespaced: true,
  state: {
    offerTypes: [],
    pointFocaux: [],
    pointFocauxWithoutPagination: []
  },
  getters: {
    getOfferTypes: state => state.offerTypes,
    getPointFocaux: state => state.pointFocaux,
    getPointFocauxWithoutPagination : state => state.pointFocauxWithoutPagination
  },
  mutations: {
    SET_OFFER_TYPES(state, val) {
      state.offerTypes = val
    },
    SET_POINT_FOCAUX(state, val) {
      state.pointFocaux = val
    },
    SET_POINT_FOCAUX_WITHOUT_PAGINATION(state, val) {
      state.pointFocauxWithoutPagination = val
    }
  },
  actions: {

    async loadMorePointFocauxAction({ commit }, url) {
      const response = await $service.getMorePointFocauxService(url)
      commit('SET_POINT_FOCAUX', response.data.data)
      return response.data
    },
    async getOfferTypesAction({ commit }) {
      const response = await $service.getOfferTypesService()
      commit('SET_OFFER_TYPES', response.data.data)
      return response.data
    },

    async getOnePointFocalAction({ commit }, id) {
      const response = await $service.getOnePointFocalService(id)
      return response.data
    },
    async pointFocalWalletOperationAction({ commit }, { id, payload }) {
      const response = await $service.pointFocalWalletOperationService({
        id,
        payload,
      })
      return response.data
    },

    async getPointFocalAction({ commit }) {
      const response = await $service.getPointFocalService()
      commit('SET_POINT_FOCAUX', response.data.data)
      return response.data
    },
    async filterPointFocalAction({ commit },payload) {
      const response = await $service.filterPointFocalService(payload)
      commit('SET_POINT_FOCAUX', response.data.data)
      return response.data
    },
    
    async getPointFocauxWithoutPaginationAction({ commit }) {
      const response = await $service.getPointFocauxWithoutPaginationService()
      commit('SET_POINT_FOCAUX_WITHOUT_PAGINATION', response.data.data)
      return response.data
    },
    async updateOnePointFocalAction({ commit }, { payload, id }) {
      const response = await $service.updateOnePointFocalService({
        payload,
        id,
      })
      return response.data
    },

    async deleteOnePointFocalAction({ commit }, id) {
      return await $service.deleteOnePointFocalService(id)
    },

    async getPlacementFeeAction({ commit }) {
      const response = await $service.getPlacementFeeService()
      return response.data
    },

    async addNewOfferTypeAction({ commit }, payload) {
      const response = await $service.addNewOfferTypeService(payload)
      return response.data
    },
    async addNewPointFocalAction({ commit }, payload) {
      const response = await $service.addNewPointFocalService(payload)
      return response.data
    },

    async updateOfferTypeAction({ commit }, { payload, id }) {
      const response = await $service.updateOfferTypeService({ payload, id })
      return response.data
    },
    async updatePlacementFeeAction({ commit }, { payload, id }) {
      const response = await $service.updatePlacementFeeService({
        payload,
        id,
      })
      return response.data
    },

    async deleteOfferTypeAction({ commit }, id) {
      return await $service.deleteOfferTypeService(id)
    },
  },
}
