import $service from '@/callApi/customer'

export default {
  namespaced: true,
  state: {
    customers: [],

    customersWithoutPagination: [],
    customersEmployeesWithoutPagination: [],
    customer: null,
    customerOrders: null,
    customerDirectOrders: null,
    customersInDept: [],
  },
  getters: {
    getCustomers: state => state.customers,
    getCustomersWithoutPagination: state => state.customersWithoutPagination,
    getCustomersEmployeesWithoutPagination: state => state.customersEmployeesWithoutPagination,
    getCustomer: state => state.customer,
    getCustomersInDept: state => state.customersInDept,
    getCustomerOrders: state => state.customerOrders,
    getCustomerDirectOrders: state => state.customerDirectOrders,
  },
  mutations: {
    clearClientData(state) {
      state.customers = null; 
    },
    SET_CUSTOMERS(state, val) {
      state.customers = val
    },

    SET_CUSTOMERS_WITHOUT_PAGINATION(state, val) {
      state.customersWithoutPagination = val
    },

     SET_CUSTOMERS_EMPLOYEES_WITHOUT_PAGINATION(state, val) {
      state.customersEmployeesWithoutPagination = val
    },

    SET_CUSTOMER(state, val) {
      state.customer = val
    },

    SET_CUSTOMER_ORDERS(state, val) {
      state.customerOrders = val
    },

    SET_CUSTOMER_DIRECT_ORDERS(state, val) {
      state.customerDirectOrders = val
    },

    SET_CUSTOMERS_IN_DEPT(state, val) {
      state.customersInDept = val
    },
  },
  actions: {
    async filterCustomersAction({ commit }, payload) {
      const response = await $service.filterCustomersService(payload)
      commit('SET_CUSTOMERS', response.data.data)
      return response.data
    },

    async advancePaymentAction({ commit }, { payload, employeeId }) {
      const response = await $service.advancePaymentActionService({ payload, employeeId })
      return response.data
    },

    async getCustomersAction({ commit }) {
      const response = await $service.getCustomersService()
      commit('SET_CUSTOMERS', response.data.data)
      return response.data
    },

    async getCustomersWithoutPaginationAction({ commit }) {
      const response = await $service.getCustomersWithoutPaginationService()
      commit('SET_CUSTOMERS_WITHOUT_PAGINATION', response.data.data)
      return response.data
    },

     async getCustomersEmployeesWithoutPaginationAction({ commit }, serviceId) {
      const response = await $service.getCustomersEmployeesWithoutPaginationService(serviceId)
      commit('SET_CUSTOMERS_EMPLOYEES_WITHOUT_PAGINATION', response.data.data)
      return response.data
    },


    async loadMoreCustomersAction({ commit }, url) {
      const response = await $service.getMoreCustomersService(url)
      commit('SET_CUSTOMERS', response.data.data)
      return response.data
    },

    async getCustomerByIdAction({ commit }, id) {
      const response = await $service.getCustomerByIdService(id)
      commit('SET_CUSTOMER', response.data.data)
      return response.data
    },
    async updateCustomerInfo({ commit }, {user,payload}) {
      const response = await $service.updateCustomerInfoService(user,payload) 
      commit('SET_CUSTOMER', response.data.data)
      return response.data
    },
    async withdrawInCustomerWalletAction({ commit }, {walletId,payload}) {
      const response = await $service.withdrawInCustomerWalletService(walletId,payload)
      return response.data
    },
    clearClient({ commit }) {
      // Effacer les informations du client
      commit('CLEAR_CLIENT');
    },
    async getCustomerWalletAction({ commit }, id) {
      const response = await $service.getCustomerWalletService(id)
      return response.data
    },

    async getCustomerOrdersAction({ commit }, id) {
      const response = await $service.getCustomerOrdersService(id)
      commit('SET_CUSTOMER_ORDERS', response.data.data)
      return response.data
    },

    async getCustomerDirectOrdersAction({ commit }, id) {
      const response = await $service.getCustomerDirectOrdersService(id)
      commit('SET_CUSTOMER_DIRECT_ORDERS', response.data.data)
      return response.data
    },

    async deleteCustomerAction({ commit }, id) {
      const response = await $service.deleteCustomerService(id)
      return response
    },

    async deleteReferredCustomersAction({ commit }, id) {
      const response = await $service.deleteReferredCustomersService(id)
      return response
    },

    async addNewCustomerAction({ commit }, payload) {
      const response = await $service.addNewCustomerService(payload)
      return response.data
    },

    async getCustomersInDeptAction({ commit }) {
      const response = await $service.getCustomersInDeptService()
      commit('SET_CUSTOMERS_IN_DEPT', response.data.data)
      return response.data
    },
  },
}
