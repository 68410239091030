import $blogService from '@/callApi/blog'

export default {
  namespaced: true,
  state: {
    blogsArticles: [],
    blogArticle: null,
    blogsCategories: [],
    blogCategory: {},
  },
  getters: {
    blogsArticles: state => state.blogsArticles,
    blogArticle: state => state.blogArticle,
    blogsCategories: state => state.blogsCategories,
    blogCategory: state => state.blogCategory,
  },
  mutations: {

    SET_BLOGS_CATEGORIES(state, val) {
      val.forEach(element => {
        state.blogsCategories.push({
          id: element.id,
          name: element.name,
        })
      })
    },
    SET_BLOG_CATEGORY(state, val) {
      state.blogCategory = val
    },
    ADD_NEW_BLOG_CATEGORY(state, val) {
      state.blogsCategories.unshift({
        id: val.id,
        name: val.name,
      })
    },
    SET_BLOGS_ARTICLES(state, val) {
      state.blogsArticles = val
    },
    SET_BLOG_ARTICLE(state, val) {
      state.blogArticle = val
    },
    ADD_NEW_BLOG_ARTICLE(state, val) {
      state.blogsArticles.data.unshift(val)
    },
    REMOVE_BLOG_ARTICLE(state, val) {
      const id = state.blogsArticles.data.findIndex(article => article.id == val)
      state.blogsArticles.data.splice(id, 1)
    },
  },
  actions: {
    async createBlogCateoryAction({ commit }, payload) {
      const response = await $blogService.createCategoryService(payload)
      commit('ADD_NEW_BLOG_CATEGORY', response.data.data)
      return response.data
    },
    async getAllCategoriesAction({ commit }) {
      const response = await $blogService.getAllCategoryService()
      commit('SET_BLOGS_CATEGORIES', response.data.data)
      return response.data
    },
    async createBlogArticleAction({ commit }, { payload }) {
      const response = await $blogService.createArticleService(payload)
      commit('SET_BLOG_ARTICLE', response.data.data)
      commit('ADD_NEW_BLOG_ARTICLE', response.data.data)

      return response.data
    },
    async getAllArticlesAction({ commit }) {
      const response = await $blogService.getAllArticleService()
      commit('SET_BLOGS_ARTICLES', response.data.data)
      return response.data
    },
    async loaadMoreArticlesAction({ commit }, url) {
      const response = await $blogService.loadMoreArticleService(url)
      commit('SET_BLOGS_ARTICLES', response.data.data)
      return response.data
    },
    async getBlogArticleDetail({ commit }, id) {
      const response = await $blogService.getArticleDetailService(id)
      commit('SET_BLOG_ARTICLE', response.data.data)
      return response.data
    },
    async validateBlogArticleAction({ commit }, id) {
      const response = await $blogService.validateArticleService(id)
      commit('SET_BLOG_ARTICLE', response.data.data)
      return response.data
    },
    async publishBlogArticleAction({ commit }, id) {
      const response = await $blogService.publishArticleService(id)
      commit('SET_BLOG_ARTICLE', response.data.data)
      return response.data
    },
    async rejectBlogArticleAction({ commit }, { payload, id }) {
      const response = await $blogService.rejectArticleService(payload, id)
      commit('SET_BLOG_ARTICLE', response.data.data)
      return response.data
    },
    async deleteBlogArticleAction({ commit }, id) {
      const response = await $blogService.deleteArticleService(id)
      commit('SET_BLOG_ARTICLE', null)
      commit('REMOVE_BLOG_ARTICLE', id)
      return response.data
    },
    async updateBlogArticleAction({ commit }, { payload, id }) {
      const response = await $blogService.updateArticleService(payload, id)
      commit('SET_BLOG_ARTICLE', response.data.data)
      return response.data
    },
  },
}
