import { objectToFormData } from '@/@core/utils/utils'
// eslint-disable-next-line import/no-cycle
import $http from '@/libs/axios';

export default {
    markSuiviService(payload) {
        const data = objectToFormData(payload)
        return $http.post(
          `/suivis/store`,
          data,
        )
      },

      updateSuiviService({ suiviId, payload }) {
        const data = objectToFormData(payload)
        return $http.post(
          `/suivis/${suiviId}/update`,
          data,
        )
      }, 

      getSuiviService({ payload }) {
        const data = objectToFormData(payload)
        return $http.post(
          `/suivis`,data
        )
      },
      filterSuiviService({ payload }) {
        const data = objectToFormData(payload)
        return $http.post(
          `/suivis/filters`,data
        ) 
      },
      getUnPublishedSuiviService({ payload }) {
        const data = objectToFormData(payload)
        return $http.post(
          `/suivis/unpublished`,data
        )
      },
    
      sendSuiviService({ payload }) {
        const data = objectToFormData(payload)
        return $http.post(
          `/suivis/rapport`,data
        )
  },
  sendRapportSuivisService() {
    return $http.get(`/suivis/rapport`)
    },
  
}
