/* eslint-disable no-unused-vars */
/* eslint-disable import/no-cycle */
import $service from '@/callApi/professionals'

export default {
  namespaced: true,
  state: {
    professionals: [],
    professionalsPerServicesAndAddress: [],
    employeesWithoutPagination: [],
    professionalsWithoutPagination: [],
    bestProfessionals: [],
    proDetails: null,
    employeeDetails: null,
    getActifEmployees: [],

    // Direct States
    employees: [],
    employeeWallet: [],
    employeeWalletWithoutPagination: [],
    employeeWalletsLogs: [],

    payedEmployees: [],
    payedAndUnpayedEmployees: [],
    unpayedEmployees: [],
    payedPros: [],
    unpayedPros: [],
  },
  getters: {
    getProfessionals: state => state.professionals,
    getProDetails: state => state.proDetails,
    getEmployeeDetails: state => state.employeeDetails,
    getEmployeesWithoutPagination: state => state.employeesWithoutPagination,
    getProfessionalsWithoutPagination: state => state.professionalsWithoutPagination,
    getBestProfessionals: state => state.bestProfessionals,
    getProfessionalsPerServicesAndAddress: state => state.professionalsPerServicesAndAddress,
    getActifEmployees: state => state.getActifEmployees,

    // Direct Getters
    getEmployees: state => state.employees,
    getEmployeesWallet: state => state.employeeWallet,
    getEmployeesWalletWithoutPagination: state => state.employeeWalletWithoutPagination,

    getEmployeesWalletsLogs: state => state.employeeWalletsLogs,

    getPayedEmployees: state => state.payedEmployees,
    getPayedAndUnpayedEmployees: state => state.payedAndUnpayedEmployees,
    getUnpayedEmployees: state => state.unpayedEmployees,

    getPayedPros: state => state.payedPros,
    getUnpayedPros: state => state.unpayedPros,
  },
  mutations: {
    SET_ACTIF_EMPLOYEES(state, val) {
      state.getActifEmployees = val
    },
    SET_PROFESSIONALS(state, val) {
      state.professionals = val
    },
    SET_EMPLOYEES_WITHOUT_PAGINATION(state, val) {
      state.employeesWithoutPagination = val
    },
    SET_PROFESSIONALS_WITHOUT_PAGINATION(state, val) {
      state.professionalsWithoutPagination = val
    },
    SET_PROFESSIONALS_PER_SERVICE_AND_ADDRESS(state, val) {
      state.professionalsPerServicesAndAddress = val
    },
    SET_BEST_PROFESSIONALS(state, val) {
      state.bestProfessionals = val
    },
    SET_PRO_DETAILS(state, val) {
      state.proDetails = val
    },
    SET_EMPLOYEE_DETAILS(state, val) {
      state.employeeDetails = val
    },
    // Direct Setters
    SET_EMPLOYEES(state, val) {
      state.employees = val
    },

    SET_EMPLOYEES_WALLET(state, val) {
      state.employeeWallet = val
    },
    SET_EMPLOYEES_WALLET_WITHOUT_PAGINATION(state, val) {
      state.employeeWallet = val
    },

    SET_EMPLOYEES_WALLETS_LOGS(state, val) {
      state.employeeWalletsLogs = val
    },
    SET_PAYED_EMPLOYEES(state, val) {
      state.payedEmployees = val
    },
    SET_PAYED_AND_UNPAYED_EMPLOYEES(state, val) {
      state.payedAndUnpayedEmployees = val
    },
    SET_UNPAYED_EMPLOYEES(state, val) {
      state.unpayedEmployees = val
    },
    SET_PAYED_PROS(state, val) {
      state.payedPros = val
    },
    SET_UNPAYED_PROS(state, val) {
      state.unpayedPros = val
    },
  },
  actions: {
    async sendEmployeeInFormationAction({ commit }, {employeeId,payload}) {
      const response = await $service.sendEmployeeInFormationService({employeeId, payload})
      return response.data
    },

    async filterEmployeesAction({ commit }, payload) {
      const response = await $service.filterEmployeesService(payload)
      commit('SET_EMPLOYEES', response.data.data)
      return response.data
    },

    async getProfessionalsAction({ commit }) {
      const response = await $service.getProfessionalsService()
      commit('SET_PROFESSIONALS', response.data.data)
      return response.data
    },
    async getCautionLinkAction({ commit }) {
      const response = await $service.getCautionLinkService()
      return response.data
    },
    async filterProfessionalsAction({ commit }, payload) {
      const response = await $service.filterProfessionalsService(payload)
      commit('SET_PROFESSIONALS', response.data.data)
      return response.data
    },

    async employeeSalaryUnpaidAction({ commit }, employeeId) {
      const response = await $service.employeeSalaryUnpaidService(employeeId)
      return response.data
    },

    async loadMoreProfessionalsAction({ commit }, url) {
      const response = await $service.getMoreProfessionalsService(url)
      commit('SET_PROFESSIONALS', response.data.data)
      return response.data
    },

    async loadMoreEmployeesAction({ commit }, url) {
      const response = await $service.getMoreEmployeesService(url)
      commit('SET_EMPLOYEES', response.data.data)
      return response.data
    },
    async loadMoreEmployeesWalletLogsAction({ commit }, url) {
      const response = await $service.getMoreEmployeesWalletLogsService(url)
      commit('SET_EMPLOYEES_WALLETS_LOGS', response.data.data)
      return response.data
    },
    async getProfessionalsMetaDataAction({ commit }, id) {
      const response = await $service.getProfessionalsMetaDataService(id)
      return response.data
    },

    async getProfessionalsCommentsAction({ commit }, id) {
      const response = await $service.getProfessionalsCommentsService(id)
      return response.data
    },

    async getProfessionalsDetailsAction({ commit }, id) {
      const response = await $service.getProfessionalsDetailsService(id)
      commit('SET_PRO_DETAILS', response.data.data)
      return response.data
    },

    async getEmployeesDetailsAction({ commit }, id) {
      const response = await $service.getEmployeesDetailsService(id)
      commit('SET_EMPLOYEE_DETAILS', response.data.data)
      return response.data
    },

    async getBestProfessionalsAction({ commit }) {
      const response = await $service.getBestProfessionalsService()
      commit('SET_BEST_PROFESSIONALS', response.data.data)
      return response.data
    },

    async getProPerServiceAndAdressAction({ commit }) {
      const response = await $service.getProPerServiceAndAddressService()
      commit('SET_PROFESSIONALS_PER_SERVICE_AND_ADDRESS', response.data.data)
      return response.data
    },

    async registerProfessionalAction({ commit }, payload) {
      const response = await $service.registerProfessionalService(payload)
      return response.data
    },

    async updateProfessionalServiceAction({ commit }, { payload, id }) {
      const response = await $service.updateProfessionalService(payload, id)
      return response.data
    },

    async suspendEmployeeAction({ commit }, { payload, empId }) {
      const response = await $service.suspendEmployeeService(payload, empId)
      return response.data
    },
    async unsuspendEmployeeAction({ commit }, {empId }) {
      const response = await $service.unsuspendEmployeeService(empId)
      return response.data
    },
    async deleteProfessionalAction({ commit }, id) {
      const response = await $service.deleteProfessionalService(id)
      return response
    },

    async downloadEmployeeContractAction({ commit }, id) {
      const response = await $service.downloadEmployeeContractService(id)
      return response.data
    },

    async validateProAction({ commit }, id) {
      const response = await $service.validateProService(id)
      return response.data
    },
    async validateCandidatureProAction({ commit }, id) {
      const response = await $service.validateCandidatureProService(id)
      return response.data
    },

    async suspendProAction({ commit }, id) {
      const response = await $service.suspendProService(id)
      return response.data
    },

    async unSuspendProAction({ commit }, id) {
      const response = await $service.unSuspendProService(id)
      return response.data
    },

    // Direct Actions
    async getEmployeesAction({ commit }) {
      const response = await $service.getEmployeesService()
      console.log("STORE EMPLOYEES",response.data.data.data)
      commit('SET_EMPLOYEES', response.data.data)
      return response.data
    },
    async signContratAction({ commit }, { propositionId, payload }) {
      const response = await $service.signContratService({ propositionId, payload })
      return response.data
    },

    async signBusinessContratAction({ commit }, { orderId, employeeId, payload }) {
      const response = await $service.signBusinessContratService({ orderId, employeeId, payload })
      return response.data
    },
    async getEmployeesWithoutPaginationAction({ commit }) {
      const response = await $service.getEmployeesWithoutPaginationService()
      commit('SET_EMPLOYEES_WITHOUT_PAGINATION', response.data.data)
      return response.data
    },
  
  async getProfessionalsWithoutPaginationAction({ commit }) {
    const response = await $service.getProfessionalsWithoutPaginationService()
    commit('SET_PROFESSIONALS_WITHOUT_PAGINATION', response.data.data)
    return response.data
  },


    async getEmployeesWalletAction({ commit }) {
      const response = await $service.getEmployeesWalletService()
      commit('SET_EMPLOYEES_WALLET', response.data.data)
      return response.data
    },

    async filterEmployeesWalletsLogsAction({ commit },payload) {
      const response = await $service.filterEmployeesWalletsLogsService(payload)
      commit('SET_EMPLOYEES_WALLETS_LOGS', response.data.data)
      return response.data
    },
    async getEmployeesWalletsLogsAction({ commit }) {
      const response = await $service.getEmployeesWalletsLogsService()
      commit('SET_EMPLOYEES_WALLETS_LOGS', response.data.data)
      return response.data
    },
    async filterEmployeesWalletAction({ commit }, payload) {
      const response = await $service.filterEmployeesWalletService(payload)
      commit('SET_EMPLOYEES_WALLET', response.data.data)
      return response.data
    },
    async loadMoreEmployeesWalletAction({ commit }, url) {
      const response = await $service.getMoreEmployeesWalletService(url)
      commit('SET_EMPLOYEES_WALLET', response.data.data)
      return response.data
    },

    async loadMorePayedEmployeesAction({ commit }, url) {
      const response = await $service.getMorePayedEmployeesService(url)
      commit('SET_PAYED_EMPLOYEES', response.data.data)
      return response.data
    },

    async loadMorePayedAndUnpayedEmployeesAction({ commit }, url) {
      const response = await $service.getMorePayedAndUnpayedEmployeesService(
        url,
      )
      commit('SET_PAYED_AND_UNPAYED_EMPLOYEES', response.data.data)
      return response.data
    },

    async loadMoreUnPayedEmployeesAction({ commit }, url) {
      const response = await $service.getMoreUnPayedEmployeesService(url)
      commit('SET_UNPAYED_EMPLOYEES', response.data.data)
      return response.data
    },

    async getEmployeesWalletDetailsAction({ commit }, id) {
      const response = await $service.getEmployeesWalletDetailsService(id)
      return response.data
    },

    async getEmployeesWalletLogsAction({ commit }, id) {
      const response = await $service.getEmployeesWalletLogsService(id)
      return response.data
    },

    async employeeWalletOperationAction({ commit }, { id, payload }) {
      const response = await $service.employeeWalletOperationService({
        id,
        payload,
      })
      return response.data
    },
    async employeeTransfertOperationAction({ commit }, { id, payload }) {
      const response = await $service.employeeTransfertOperationService({
        id,
        payload,
      })
      return response.data
    },

    async getBdpSavedEmployeesAction({ commit }, bdpId) {
      const response = await $service.getBdpSavedEmployeesService(bdpId)
      commit('SET_EMPLOYEES', response.data.data)
      return response.data
    },

    async getBdpSavedProsAction({ commit }, bdpId) {
      const response = await $service.getBdpSavedProsService(bdpId)
      commit('SET_PROFESSIONALS', response.data.data)
      return response.data
    },

    async assignProRegistrationAction({ commit }, { proId, payload }) {
      const response = await $service.assignProRegistrationService({
        proId,
        payload,
      })
      return response
    },

    async registerEmployeeAction({ commit }, payload) {
      const response = await $service.registerEmployeeService(payload)
      return response.data
    },

    async addEmployeeServiceAction({ commit }, {employeeId, payload}) {
      const response = await $service.addEmployeeServiceService(employeeId, payload)
      return response.data
    },

    async validateEmployeeAction({ commit }, id) {
      const response = await $service.validateEmployeeService(id)
      commit('SET_EMPLOYEE_DETAILS', response.data.data)
      return response.data
    },
    async validateEmployeeCandidatureAction({ commit }, id) {
      const response = await $service.validateEmployeeCandidatureService(id)
      commit('SET_EMPLOYEE_DETAILS', response.data.data)
      return response.data
    },
    
    async deleteEmployeeAction({ commit }, id) {
      const response = await $service.deleteEmployeeService(id)
      return response
    },

    async deleteServiceEmployeeAction({ commit }, {employeeId, serviceId}) {
      const response = await $service.deleteServiceEmployeeService(employeeId, serviceId)
      return response
    },

    async updateEmployeeServiceAction({ commit }, { payload, employeeId, employeeRecurringService }) {
      const response = await $service.updateEmployeeService(payload, employeeId, employeeRecurringService)
      return response.data
    },

    async getPayedEmployeesAction({ commit }) {
      const response = await $service.getPayedEmployeesService()
      commit('SET_PAYED_EMPLOYEES', response.data.data)
      return response.data
    },

    async getPayedAndUnpayedEmployeesAction({ commit }) {
      const response = await $service.getPayedAndUnpayedEmployeesService()
      commit('SET_PAYED_AND_UNPAYED_EMPLOYEES', response.data.data)
      return response.data
    },

    async getUnpayedEmployeesAction({ commit }) {
      const response = await $service.getUnpayedEmployeesService()
      commit('SET_UNPAYED_EMPLOYEES', response.data.data)
      return response.data
    },

    async confirmEmployeesSalaryPaymentAction({ commit }, payload) {
      const response = await $service.confirmEmployeesSalaryPaymentService(
        payload,
      )
      return response.data
    },

    async getPayedProsAction({ commit }) {
      const response = await $service.getPayedProsService()
      commit('SET_PAYED_PROS', response.data.data)
      return response.data
    },

    async getUnpayedProsAction({ commit }) {
      const response = await $service.getUnpayedProsService()
      commit('SET_UNPAYED_PROS', response.data.data)
      return response.data
    },

    async confirmProsPaymentAction({ commit }, payload) {
      const response = await $service.confirmProsPaymentService(payload)
      return response.data
    },

    async confirmPlacementCostPaidAction({ commit }, id) {
      const response = await $service.confirmPlacementCostPaidService(id)
      return response
    },

    async confirmSalaryPaiementAction(
      { commit },
      { id, directSalaryPaiementId, paymentMethod },
    ) {
      const response = await $service.confirmSalaryPaiementService({
        id,
        directSalaryPaiementId,
        paymentMethod,
      })
      return response
    },
    async blockSalaryPaiementAction({ commit },{id}) {
      const response = await $service.blockSalaryPaymentService({id})
      return response
    },
     async unBlockSalaryPaiementAction({ commit },{id}) {
      const response = await $service.unBlockSalaryPaymentService({id})
      return response
    },
     async getEmployeeNoteAction({ commit }, {employeeId, serviceId}) {
      const response = await $service.getEmployeeNoteServices({employeeId, serviceId})
      return response.data
    },
    async deleteEmployeeRecommendationAction(
      { commit },
      { employeeRecommendationId },
    ) {
      const response = await $service.deleteEmployeeRecommendationService({
        employeeRecommendationId,
      })
      return response
    },

    async deleteEmployeeAffectationAction(
      { commit },
      { employeeAffectationId },
    ) {
      const response = await $service.deleteEmployeeAffectationService({
        employeeAffectationId,
      })
      return response
    },
     async getActifEmployeesAction({ commit }, {packageId}) {
       const response = await $service.getActifEmployeesServices({ packageId })
      commit('SET_ACTIF_EMPLOYEES', response.data.data)
      return response.data
    },
    async rejectEmployeeAction({ commit }, { employeeId, payload }) {
      const response = await $service.rejectEmployeeService({
        employeeId,
        payload,
      })
      return response.data
    },
    async getIndicesAction(){
      const response = await $service.getIndicesService()
      return response.data
    },

    async addIndicesAction({ commit },payload){
      const response = await $service.addIndicesService(payload)
      return response.data
    }
  }
  }

