/* eslint-disable import/no-cycle */
import { objectToFormData } from '@/@core/utils/utils'
import $http from '@/libs/axios'

export default {
  getMostRequestedServices() {
    return $http.get('/services/most/requested')
  },
  getMoreServices(url) {
    return $http.get(`${url}`)
  },
  getMostRequestedRecurringServices() {
    return $http.get('/recurring/services/most/requested')
  },
  getServicesWithoutPaginationService() {
    return $http.get('/services/without/pagination')
  },

  getRecurringServicesWithoutPaginationService() {
    return $http.get('/recurring/services/without/pagination')
  },

  filterServicesService(payload) {
    const data = objectToFormData(payload)
    return $http.post('/services', data)
  },

  filterRecurringServicesService(payload) {
    const data = objectToFormData(payload)
    return $http.post('/recurring/services', data)
  },

  getServices() {
    return $http.get('/services')
  },
  getServiceProfessionals(id) {
    return $http.get(`/services/${id}/professionals`)
  },
  addService(payload) {
    const data = objectToFormData(payload)
    return $http.post('/services', data)
  },
  updateService(payload, id) {
    const data = objectToFormData(payload)
    return $http.post(`/services/${id}`, data)
  },
  deleteService(id) {
    return $http.delete(`/services/${id}`)
  },
  archiveService(id) {
    return $http.get(`/services/${id}/archive`)
  },

  // Direct Services
  getRecurringServices() {
    return $http.get('/recurring/services')
  },
  getMoreRecurringServices(url) {
    return $http.get(`${url}`)
  },
  addRecurringService(payload) {
    const data = objectToFormData(payload)
    return $http.post('/recurring/services/new', data)
  },
  updateRecurringService(payload, id) {
    const data = objectToFormData(payload)
    return $http.post(`/recurring/services/${id}`, data)
  },
  deleteRecurringService(id) {
    return $http.delete(`/recurring/services/${id}`)
  },
  getServiceEmployees(id) {
    return $http.get(`/recurring/services/${id}/formed/employees`)
  },
  getQuizService(serviceId) {
    return $http.get(`recurring/services/${serviceId}/quiz`)
  },
  addQuizService(payload) {
   
    return $http.post('/quiz', payload)
  },
  deleteQuizService(id) {
    return $http.delete(`/quiz/${id}`)
  },
  updateQuizService({payload, id}) {
    return $http.post(`/quiz/${id}`, payload)
  },
}
