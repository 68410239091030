/* eslint-disable prefer-arrow-callback */
import Vue from 'vue'
// eslint-disable-next-line import/no-cycle
import axios from 'axios'
import store from '@/store'
import router from '@/router'
// axios
// Valider la résiliation par un admin
const baseURL = {
  serverProd: 'https://api-ylomi.herokuapp.com/api',
  local: 'http://127.0.0.1:8000/api',
  serverTest: 'https://testapiylomi.herokuapp.com/api',
}

const axiosIns = axios.create({
  baseURL: baseURL.serverProd,
  headers: {
    common: {
      'Content-Type': 'multipart/form-data',
    },
  },
})

// eslint-disable-next-line func-names
axiosIns.interceptors.request.use(function (config) {
  const token = localStorage.getItem('token')
  token ? config.headers.Authorization = `Bearer ${token}` : null
  return config
  // eslint-disable-next-line func-names
}, function (error) {
  return Promise.reject(error)
})

// eslint-disable-next-line func-names
axiosIns.interceptors.response.use(function (response) {
  return response
  // eslint-disable-next-line func-names
}, function (error) {
  if (error.response.status === 401) {
    store.dispatch('auth/removeToken')
    router.push({ name: 'login' })
  }
  return Promise.reject(error)
})

Vue.prototype.$http = axiosIns

export default axiosIns
