/* eslint-disable import/extensions */
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [{
    path: '/',
    redirect: 'authentication',
  },
  {
    path: '/dashboard',
    name: 'dashboard-index',
    component: () => import('@/views/dashboard/index.vue'),
    beforeEnter(to, from, next) {
      const token = localStorage.getItem('token')
      if (!token) next({ name: 'login' })
      else next()
    },
  },
  {
    path: '/pointfocals/:id',
    name: 'point-focal-wallet',
    component: () => import('@/views/work-providers/pointFocaux/wallet/Details'),
    beforeEnter(to, from, next) {
      const token = localStorage.getItem('token')
      if (!token) next({ name: 'login' })
      else next()
    },
  },

  {
    path: '/dashboard/statistics/finance',
    name: 'dashboard-statistics-finance',
    component: () => import('@/views/statistics/Finance.vue'),
    beforeEnter(to, from, next) {
      const token = localStorage.getItem('token')
      if (!token) next({ name: 'login' })
      else next()
    },
  },
  {
    path: '/dashboard/statistics/taux',
    name: 'dashboard-statistics-taux',
    component: () => import('@/views/statistics/Taux.vue'),
    beforeEnter(to, from, next) {
      const token = localStorage.getItem('token')
      if (!token) next({ name: 'login' })
      else next()
    },
  },
  {
    path: '/dashboard/statistics/numbers',
    name: 'dashboard-statistics-numbers',
    component: () => import('@/views/statistics/Numbers.vue'),
    beforeEnter(to, from, next) {
      const token = localStorage.getItem('token')
      if (!token) next({ name: 'login' })
      else next()
    },
  },
  {
    path: '/dashboard/statistics/ca',
    name: 'dashboard-statistics-ca',
    component: () => import('@/views/statistics/CA.vue'),
    beforeEnter(to, from, next) {
      const token = localStorage.getItem('token')
      if (!token) next({ name: 'login' })
      else next()
    },
  },
  {
    path: '/dashboard/statistics/bdp',
    name: 'dashboard-statistics-bdp',
    component: () => import('@/views/statistics/BDP.vue'),
    beforeEnter(to, from, next) {
      const token = localStorage.getItem('token')
      if (!token) next({ name: 'login' })
      else next()
    },
  },
  {
    path: '/dashboard/statistics/cc',
    name: 'dashboard-statistics-cc',
    component: () => import('@/views/statistics/CC.vue'),
    beforeEnter(to, from, next) {
      const token = localStorage.getItem('token')
      if (!token) next({ name: 'login' })
      else next()
    },
  },
  {
    path: '/dashboard/statistics/points/focaux',
    name: 'points-focaux',
    component: () => import('@/views/statistics/PointsFocaux.vue'),
    beforeEnter(to, from, next) {
      const token = localStorage.getItem('token')
      if (!token) next({ name: 'login' })
      else next()
    },
  },
  {
    path: '/preview/pdf/',
    name: 'preview-pdf',
    component: () => import('@/views/pdf/PreviewPdf.vue'),
    beforeEnter(to, from, next) {
      const token = localStorage.getItem('token')
      if (!token) next({ name: 'login' })
      else next()
    },
  },
  {
    path: '/orders/recurring',
    name: 'admin-orders-recurring',
    component: () => import('@/views/order/recurring/Index'),
    beforeEnter(to, from, next) {
      const token = localStorage.getItem('token')
      if (!token) next({ name: 'login' })
      else next()
    },
  },
  {
    path: '/orders/recurring/customers/meet',
    name: 'admin-customers-meet',
    component: () => import('@/views/order/recurring/CustomerMeet'),
    beforeEnter(to, from, next) {
      const token = localStorage.getItem('token')
      if (!token) next({ name: 'login' })
      else next()
    },
  },
  {
    path: '/orders/punctual',
    name: 'admin-orders-punctual',
    component: () => import('@/views/order/punctual/Index'),
    beforeEnter(to, from, next) {
      const token = localStorage.getItem('token')
      if (!token) next({ name: 'login' })
      else next()
    },
  },
  {
    path: '/orders/business',
    name: 'admin-orders-business',
    component: () => import('@/views/order/business/Index'),
    beforeEnter(to, from, next) {
      const token = localStorage.getItem('token')
      if (!token) next({ name: 'login' })
      else next()
    },
  },
  {
    path: '/orders/recurring/:id/details',
    name: 'admin-orders-recurring-details',
    component: () => import('@/views/order/recurring/details/index.vue'),
    beforeEnter(to, from, next) {
      const token = localStorage.getItem('token')
      if (!token) next({ name: 'login' })
      else next()
    },
  },
  {
    path: '/orders/punctual/:id/details',
    name: 'admin-orders-punctual-details',
    component: () => import('@/views/order/punctual/Details.vue'),
    beforeEnter(to, from, next) {
      const token = localStorage.getItem('token')
      if (!token) next({ name: 'login' })
      else next()
    },
  },
  {
    path: '/orders/recurring/payment/history',
    name: 'admin-orders-recurring-payment-history',
    component: () => import('@/views/order/recurring/PaymentHistory.vue'),
    beforeEnter(to, from, next) {
      const token = localStorage.getItem('token')
      if (!token) next({ name: 'login' })
      else next()
    },
  },
  {
    path: '/orders/recurring/unsuccessful',
    name: 'admin-orders-recurrent-unsuccessful',
    component: () => import('@/views/order/recurring/UnsuccessfulOrders'),
    beforeEnter(to, from, next) {
      const token = localStorage.getItem('token')
      if (!token) next({ name: 'login' })
      else next()
    },
  },
  {
    path: '/orders/recurring/archived',
    name: 'admin-orders-recurrent-archived',
    component: () => import('@/views/order/recurring/Archived'),
    beforeEnter(to, from, next) {
      const token = localStorage.getItem('token')
      if (!token) next({ name: 'login' })
      else next()
    },
  },
  {
    path: '/suivis',
    name: 'suivis-clients-employees',
    component: () => import('@/views/suivis/Index.vue'),
    beforeEnter(to, from, next) {
      const token = localStorage.getItem('token')
      if (!token) next({ name: 'login' })
      else next()
    },
  },
  {
    path: '/employees',
    name: 'admin-work-providers-employees',
    component: () => import('@/views/work-providers/employees/Index'),
    beforeEnter(to, from, next) {
      const token = localStorage.getItem('token')
      if (!token) next({ name: 'login' })
      else next()
    },
  },
  {
    path: '/points-focaux',
    name: 'admin-work-providers-points-focaux',
    component: () => import('@/views/work-providers/pointFocaux/Index'),
    beforeEnter(to, from, next) {
      const token = localStorage.getItem('token')
      if (!token) next({ name: 'login' })
      else next()
    },
  },
  {
    path: '/work/providers/recommandations',
    name: 'admin-work-providers-recommandations',
    component: () => import('@/views/work-providers/PendingDirectOrders'),
    beforeEnter(to, from, next) {
      const token = localStorage.getItem('token')
      if (!token) next({ name: 'login' })
      else next()
    },
  },
  {
    path: '/professionals',
    name: 'admin-work-providers-professionals',
    component: () => import('@/views/work-providers/professionals/Index'),
    beforeEnter(to, from, next) {
      const token = localStorage.getItem('token')
      if (!token) next({ name: 'login' })
      else next()
    },
  },
  {
    path: '/employees/wallet',
    name: 'admin-employees-wallet-list',
    component: () => import('@/views/work-providers/employees/wallet/Index.vue'),
    beforeEnter(to, from, next) {
      const token = localStorage.getItem('token')
      if (!token) next({ name: 'login' })
      else next()
    },
  },
  {
    path: '/employees/payslip',
    name: 'admin-employees-payslip',
    component: () => import('@/views/work-providers/employees/payslip/Index.vue'),
    beforeEnter(to, from, next) {
      const token = localStorage.getItem('token')
      if (!token) next({ name: 'login' })
      else next()
    },
  },
  {
    path: '/employees/formation',
    name: 'admin-employees-formation',
    component: () => import('@/views/formation/formation.vue'),
    beforeEnter(to, from, next) {
      const token = localStorage.getItem('token')
      if (!token) next({ name: 'login' })
      else next()
    },
  },
  {
    path: '/employees/payed-and-unpayed',
    name: 'admin-employees-payed-and-unpayed-list',
    component: () => import('@/views/work-providers/employees/PayedAndUnpayedEmployees.vue'),
    beforeEnter(to, from, next) {
      const token = localStorage.getItem('token')
      if (!token) next({ name: 'login' })
      else next()
    },
  },

  {
    path: '/employees/wallet/:id',
    name: 'admin-employees-wallet',
    component: () => import('@/views/work-providers/employees/wallet/Details'),
    beforeEnter(to, from, next) {
      const token = localStorage.getItem('token')
      if (!token) next({ name: 'login' })
      else next()
    },
  },
  {
    path: '/notification',
    name: 'notifications',
    component: () => import('@/views/notification/Index'),
    beforeEnter(to, from, next) {
      const token = localStorage.getItem('token')
      if (!token) next({ name: 'login' })
      else next()
    },
  },
  {
    path: '/blog',
    name: 'blog',
    component: () => import('@/views/blog/BlogList.vue'),
    beforeEnter(to, from, next) {
      const token = localStorage.getItem('token')
      if (!token) next({ name: 'login' })
      else next()
    },
  },
  {
    path: '/blog/detail/:id',
    name: 'blog-detail',
    component: () => import('@/views/blog/BlogDetail.vue'),
    beforeEnter(to, from, next) {
      const token = localStorage.getItem('token')
      if (!token) next({ name: 'login' })
      else next()
    },
  },
  {
    path: '/blog/update/:id',
    name: 'blog-update',
    component: () => import('@/views/blog/BlogUpdate.vue'),
    beforeEnter(to, from, next) {
      const token = localStorage.getItem('token')
      if (!token) next({ name: 'login' })
      else next()
    },
  },
  {
    path: '/blog/new-article',
    name: 'new-article',
    component: () => import('@/views/blog/BlogEdit.vue'),
    beforeEnter(to, from, next) {
      const token = localStorage.getItem('token')
      if (!token) next({ name: 'login' })
      else next()
    },
  },
  {
    path: '/professionals/new',
    name: 'admin-pros-new',
    component: () => import('@/views/work-providers/professionals/RegisterPro.vue'),
    beforeEnter(to, from, next) {
      const token = localStorage.getItem('token')
      if (!token) next({ name: 'login' })
      else next()
    },
  },
  {
    path: '/professionals/:id/details',
    name: 'admin-pros-details',
    component: () => import('@/views/work-providers/professionals/Details.vue'),
    beforeEnter(to, from, next) {
      const token = localStorage.getItem('token')
      if (!token) next({ name: 'login' })
      else next()
    },
  },
  {
    path: '/professionals/:id/update',
    name: 'admin-pros-update',
    component: () => import('@/views/work-providers/professionals/UpdateProInfos.vue'),
    beforeEnter(to, from, next) {
      const token = localStorage.getItem('token')
      if (!token) next({ name: 'login' })
      else next()
    },
  },
  {
    path: '/customers',
    name: 'admin-customers',
    component: () => import('@/views/customers/Index.vue'),
    beforeEnter(to, from, next) {
      const token = localStorage.getItem('token')
      if (!token) next({ name: 'login' })
      else next()
    },
  },

  {
    path: '/customers/:id/details',
    name: 'admin-customers-details',
    component: () => import('@/views/customers/Details.vue'),
    beforeEnter(to, from, next) {
      const token = localStorage.getItem('token')
      if (!token) next({ name: 'login' })
      else next()
    },
  },

  {
    path: '/services',
    name: 'admin-services',
    component: () => import('@/views/services/punctual'),
    beforeEnter(to, from, next) {
      const token = localStorage.getItem('token')
      if (!token) next({ name: 'login' })
      else next()
    },
  },
  {
    path: '/access',
    name: 'admin-access',
    component: () => import('@/views/access/Index.vue'),
    beforeEnter(to, from, next) {
      const token = localStorage.getItem('token')
      if (!token) next({ name: 'login' })
      else next()
    },
  },

  {
    path: '/users',
    name: 'admin-users',
    component: () => import('@/views/users/Index.vue'),
    beforeEnter(to, from, next) {
      const token = localStorage.getItem('token')
      if (!token) next({ name: 'login' })
      else next()
    },
  },
  {
    path: '/account/details',
    name: 'account-details',
    component: () => import('@/views/profile/Index.vue'),
    beforeEnter(to, from, next) {
      const token = localStorage.getItem('token')
      if (!token) next({ name: 'login' })
      else next()
    },
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('@/views/auth/Register.vue'),
    beforeEnter(to, from, next) {
      const token = localStorage.getItem('token')
      const invitationToken = localStorage.getItem('invitationToken')
      if (token) next({ name: 'dashboard-index' })
      else if (!invitationToken) next({ name: 'login' })
      else next()
    },
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/invitation/:token/checking/:random',
    name: 'verify-invitation',
    component: () => import('@/views/users/VerifyInvitation.vue'),
    beforeEnter(to, from, next) {
      const token = localStorage.getItem('token')
      if (token) next({ name: 'dashboard-index' })
      else next()
    },
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/authentication',
    name: 'login',
    component: () => import('@/views/auth/Login.vue'),
    beforeEnter(to, from, next) {
      const token = localStorage.getItem('token')
      if (token) next({ name: 'dashboard-index' })
      else next()
    },
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/forgot/password',
    name: 'forgot-password',
    component: () => import('@/views/auth/ForgotPassword.vue'),
    beforeEnter(to, from, next) {
      const token = localStorage.getItem('token')
      if (token) next({ name: 'dashboard-index' })
      else next()
    },
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/reset/password',
    name: 'reset-password',
    component: () => import('@/views/auth/ResetPassword.vue'),
    beforeEnter(to, from, next) {
      const token = localStorage.getItem('token')
      if (token) next({ name: 'dashboard-index' })
      else next()
    },
    meta: {
      layout: 'full',
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/error/Error404.vue'),
    meta: {
      layout: 'full',
    },
  },
  {
    path: '*',
    redirect: 'error-404',
  },

  // Direct Routes

  {
    path: '/orders/direct/new/command',
    name: 'admin-direct-orders-new-command',
    component: () => import('@/views/order/recurring/NewOrder.vue'),
    beforeEnter(to, from, next) {
      const token = localStorage.getItem('token')
      if (!token) next({ name: 'login' })
      else next()
    },
  },
  {
    path: '/orders/direct/new/commandGestion',
    name: 'admin-direct-orders-new-commandGestion',
    component: () => import('@/views/order/recurring/NewGestionOrder.vue'),
    beforeEnter(to, from, next) {
      const token = localStorage.getItem('token')
      if (!token) next({ name: 'login' })
      else next()
    },
  },
  {
    path: '/orders/recurrent/:id/details',
    name: 'admin-recurrent-orders-details',
    component: () => import('@/views/order/recurring/details/Main'),
    beforeEnter(to, from, next) {
      const token = localStorage.getItem('token')
      if (!token) next({ name: 'login' })
      else next()
    },
  },
  {
    path: '/orders/direct/:id/modify',
    name: 'admin-direct-order-modify',
    component: () => import('@/views/order/recurring/ModifyOrder.vue'),
    beforeEnter(to, from, next) {
      const token = localStorage.getItem('token')
      if (!token) next({ name: 'login' })
      else next()
    },
  },
  {
    path: '/orders/direct/:id/modify/feedback',
    name: 'admin-direct-order-modify-feedback',
    component: () => import('@/views/order/recurring/ModifyFeedback.vue'),
    beforeEnter(to, from, next) {
      const token = localStorage.getItem('token')
      if (!token) next({ name: 'login' })
      else next()
    },
  },
  {
    path: '/recurring/services/',
    name: 'admin-direct-services',
    component: () => import('@/views/services/recurring/Index.vue'),
    beforeEnter(to, from, next) {
      const token = localStorage.getItem('token')
      if (!token) next({ name: 'login' })
      else next()
    },
  },
  {
    path: '/recurring/services/:id/quiz',
    name: 'admin-direct-services-quiz',
    component: () => import('@/views/services/recurring/Quiz.vue'),
    beforeEnter(to, from, next) {
      const token = localStorage.getItem('token')
      if (!token) next({ name: 'login' })
      else next()
    },
  },
  {
    path: '/employees',
    name: 'admin-direct-employees',
    component: () => import('@/views/work-providers/employees/Index.vue'),
    beforeEnter(to, from, next) {
      const token = localStorage.getItem('token')
      if (!token) next({ name: 'login' })
      else next()
    },
  },
  {
    path: '/employees/:id/details',
    name: 'admin-direct-employee-details',
    component: () => import('@/views/work-providers/employees/Details.vue'),
    beforeEnter(to, from, next) {
      const token = localStorage.getItem('token')
      if (!token) next({ name: 'login' })
      else next()
    },
  },
  {
    path: '/employees/new',
    name: 'admin-direct-employees-new',
    component: () => import('@/views/work-providers/employees/RegisterEmployee.vue'),
    beforeEnter(to, from, next) {
      const token = localStorage.getItem('token')
      if (!token) next({ name: 'login' })
      else next()
    },
  },
  {
    path: '/employees/:id/update',
    name: 'admin-direct-employee-update',
    component: () => import('@/views/work-providers/employees/UpdateEmployeeInfos.vue'),
    beforeEnter(to, from, next) {
      const token = localStorage.getItem('token')
      if (!token) next({ name: 'login' })
      else next()
    },
  },
  {
    path: '/employees/:id/update/service',
    name: 'admin-direct-employee-update-service',
    component: () => import('@/views/work-providers/employees/EditEmployeeServicesTab.vue'),
    beforeEnter(to, from, next) {
      const token = localStorage.getItem('token')
      if (!token) next({ name: 'login' })
      else next()
    },
  },
  {
    path: '/employees/:id/add/service',
    name: 'admin-direct-employee-add-service',
    component: () => import('@/views/work-providers/employees/AddEmployeeService.vue'),
    beforeEnter(to, from, next) {
      const token = localStorage.getItem('token')
      if (!token) next({ name: 'login' })
      else next()
    },
  },
  {
    path: '/packages/:id/recurring/orders',
    name: 'commande',
    component: () => import('@/views/order/recurring/Command.vue'),
    beforeEnter(to, from, next) {
      const token = localStorage.getItem('token')
      if (!token) next({ name: 'login' })
      else next()
    },
  },
  {
    path: '/packages/:id/work/provider/recomandation',
    name: 'recommandation',
    component: () => import('@/views/work-providers/Recommandation.vue'),
    beforeEnter(to, from, next) {
      const token = localStorage.getItem('token')
      if (!token) next({ name: 'login' })
      else next()
    },
  },
  {
    path: '/recommandation/orders/:id/details',
    name: 'recommandation-orders-details',
    component: () => import('@/views/work-providers/Details.vue'),
    beforeEnter(to, from, next) {
      const token = localStorage.getItem('token')
      if (!token) next({ name: 'login' })
      else next()
    },
  },
  {
    path: '/recommandation/make/:id/service',
    name: 'recommandation-make',
    component: () => import('@/views/work-providers/MakeRecommandation.vue'),
    beforeEnter(to, from, next) {
      const token = localStorage.getItem('token')
      if (!token) next({ name: 'login' })
      else next()
    },
  },
  {
    path: '/order/punctual/make/:id/offer',
    name: 'offer-make',
    component: () => import('@/views/order/punctual/MakeOffer.vue'),
    beforeEnter(to, from, next) {
      const token = localStorage.getItem('token')
      if (!token) next({ name: 'login' })
      else next()
    },
  },
  {
    path: '/propositions/recurrent/:id/details',
    name: 'proposition-details',
    component: () => import('@/views/order/recurring/details/PropositionDetails.vue'),
    beforeEnter(to, from, next) {
      const token = localStorage.getItem('token')
      if (!token) next({ name: 'login' })
      else next()
    },
  },

  {
    path: '/activity',
    name: 'activity',
    component: () => import('@/views/order/recurring/Activity.vue'),
    beforeEnter(to, from, next) {
      const token = localStorage.getItem('token')
      if (!token) next({ name: 'login' })
      else next()
    },
  },
  {
    path: '/client/payment/portefeuille/list',
    name: 'admin-clients-payment-by-portefeuille-list',
    component: () => import('@/views/order/recurring/ClientsPaymentPortefeuilleList.vue'),
    beforeEnter(to, from, next) {
      const token = localStorage.getItem('token')
      if (!token) next({ name: 'login' })
      else next()
    },
  },
  {
    path: '/client/tansactions',
    name: 'admin-clients-transactions',
    component: () => import('@/views/order/recurring/Transactions.vue'),
    beforeEnter(to, from, next) {
      const token = localStorage.getItem('token')
      if (!token) next({ name: 'login' })
      else next()
    },
  },
  {
    path: '/orders/:id/feedback/ca',
    name: 'admin-feedback-ca',
    component: () => import('@/views/order/recurring/FeedbackCA.vue'),
    beforeEnter(to, from, next) {
      const token = localStorage.getItem('token')
      if (!token) next({ name: 'login' })
      else next()
    },
  },
  // Business Orders
  {
    path: '/orders/business/new/command',
    name: 'admin-business-orders-new-command',
    component: () => import('@/views/order/business/NewBusinessOrder.vue'),
    beforeEnter(to, from, next) {
      const token = localStorage.getItem('token')
      if (!token) next({ name: 'login' })
      else next()
    },
  },
  {
    path: '/work/providers/recommandations/business',
    name: 'admin-work-providers-recommandations/business',
    component: () => import('@/views/work-providers/business/PendingBusinessOrders'),
    beforeEnter(to, from, next) {
      const token = localStorage.getItem('token')
      if (!token) next({ name: 'login' })
      else next()
    },
  },
  {
    path: '/packages/:id/business/orders',
    name: 'business-commande',
    component: () => import('@/views/order/business/BusinessOrders.vue'),
    beforeEnter(to, from, next) {
      const token = localStorage.getItem('token')
      if (!token) next({ name: 'login' })
      else next()
    },
  },
  {
    path: '/orders/business/archived',
    name: 'orders-business-archived',
    component: () => import('@/views/order/business/ArchivedBusinessOrders'),
    beforeEnter(to, from, next) {
      const token = localStorage.getItem('token')
      if (!token) next({ name: 'login' })
      else next()
    },
  },
  {
    path: '/business/orders/punctual',
    name: 'admin--business-orders-punctual',
    component: () => import('@/views/order/business/punctual/Index'),
    beforeEnter(to, from, next) {
      const token = localStorage.getItem('token')
      if (!token) next({ name: 'login' })
      else next()
    },
  },
  {
    path: '/orders/business/recurring/:id/details',
    name: 'admin-orders-business-recurring-details',
    component: () => import('@/views/order/business/details/Index.vue'),
    beforeEnter(to, from, next) {
      const token = localStorage.getItem('token')
      if (!token) next({ name: 'login' })
      else next()
    },
  },
  {
    path: '/packages/:id/work/provider/business/affectation',
    name: 'business-affectation',
    component: () => import('@/views/work-providers/business/BusinessAffectation.vue'),
    beforeEnter(to, from, next) {
      const token = localStorage.getItem('token')
      if (!token) next({ name: 'login' })
      else next()
    },
  },
  {
    path: '/packages/:id/work/provider/business/orders',
    name: 'business-orders',
    component: () => import('@/views/work-providers/business/BusinessOrders.vue'),
    beforeEnter(to, from, next) {
      const token = localStorage.getItem('token')
      if (!token) next({ name: 'login' })
      else next()
    },
  },
  {
    path: '/business/recommandation/business/orders/:id/details',
    name: 'business-recommandation-orders-details',
    component: () => import('@/views/work-providers/business/BusinessOrderDetail.vue'),
    beforeEnter(to, from, next) {
      const token = localStorage.getItem('token')
      if (!token) next({ name: 'login' })
      else next()
    },
  },
  {
    path: '/affectation/make/:id/service',
    name: 'affectation-make',
    component: () => import('@/views/work-providers/business/MakeAffectation.vue'),
    beforeEnter(to, from, next) {
      const token = localStorage.getItem('token')
      if (!token) next({ name: 'login' })
      else next()
    },
  },
  {
    path: '/orders/business/punctual/:id/details',
    name: 'admin-orders-business-punctual-details',
    component: () => import('@/views/order/business/punctual/Details.vue'),
    beforeEnter(to, from, next) {
      const token = localStorage.getItem('token')
      if (!token) next({ name: 'login' })
      else next()
    },
  },
  {
    path: '/business/order/punctual/make/:id/offer',
    name: 'business-offer-make',
    component: () => import('@/views/order/business/punctual/MakeBusinessOffer.vue'),
    beforeEnter(to, from, next) {
      const token = localStorage.getItem('token')
      if (!token) next({ name: 'login' })
      else next()
    },
  },
  {
    path: '/orders/business/recurring/payment/history',
    name: 'admin-orders-business-recurring-payment-history',
    component: () => import('@/views/order/business/BusinessPayment.vue'),
    beforeEnter(to, from, next) {
      const token = localStorage.getItem('token')
      if (!token) next({ name: 'login' })
      else next()
    },
  },
  {
    path: '/orders/business/recurrent/:id/modify',
    name: 'admin-business-order-modify',
    component: () => import('@/views/order/business/ModifyBusinessOrder.vue'),
    beforeEnter(to, from, next) {
      const token = localStorage.getItem('token')
      if (!token) next({ name: 'login' })
      else next()
    },
  },

  {
    path: '/business/order/:id/terminate/employee/contract',
    name: 'terminate-contract',
    component: () => import('@/views/order/business/details/Resiliation.vue'),
    beforeEnter(to, from, next) {
      const token = localStorage.getItem('token')
      if (!token) next({ name: 'login' })
      else next()
    },
  },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
