/* eslint-disable no-unused-vars */
// eslint-disable-next-line import/no-cycle
import $service from '@/callApi/orders'

export default {
  namespaced: true,
  state: {
    orders: [],
    order: null,
    packages: [],
    canceledOrders: [],
    transactions: [],
    clientByPortefeuille: [],
    activity: [],
    customersMeet: [],
    businessOrders: [],
    businessOrdersInPackage: [],
    currentPackage: null,

    // Direct States

    directOrders: [],
    directOrderActif: [],
    directOrder: null,
    directOrderProposedEmployees: [],
    terminatedEmployees: [],
    notCompletedDirectOrders: null,
    archivedDirectOrders: [],
    archivedBusinessOrders: [],
    directOrdersPaymentHistory: [],
    businessOrdersPaymentHistory: [],
    directOrdersPaymentEmployees: [],
    directOrdersSalaryPaymentByOrder: [],
    directOrdersHaveProposal: null,
    directOrdersHaveNotProposal: null,
    directOrdersWhoNeedRecommandation: null,
    automaticPaymentValidation: [],
  },
  getters: {
    getPackages: state => state.packages,
    getBusinessCurrentPackage: state => state.currentPackage,
    getOrders: state => state.orders,
    getOrder: state => state.order,
    getCanceledOrders: state => state.canceledOrders,
    getDirectOrderActif: state => state.directOrderActif,
    getTransactions: state => state.transactions,
    getClientByPortefeuille: state => state.clientByPortefeuille,
    getActivity: state => state.activity,
    getCustomersMeet: state => state.customersMeet,
    // Direct Getters

    getDirectOrders: state => state.directOrders,
    getBusinessOrders: state => state.businessOrders,
    getBusinessOrdersInPackage: state => state.businessOrdersInPackage,
    getDirectOrder: state => state.directOrder,
    getAutomaticPaymentValidation: state => state.automaticPaymentValidation,
    getDirectOrderProposedEmployees: state => state.directOrderProposedEmployees,
    getTerminatedEmployees: state => state.terminatedEmployees,
    getNotCompletedDirectOrders: state => state.notCompletedDirectOrders,
    getArchivedDirectOrders: state => state.archivedDirectOrders,
    getArchivedBusinessOrders: state => state.archivedBusinessOrders,
    getDirectOrdersPaymentHistory: state => state.directOrdersPaymentHistory,
    getBusinessOrdersPaymentHistory: state => state.businessOrdersPaymentHistory,
    getDirectOrdersPaymentEmployees: state => state.directOrdersPaymentEmployees,
    getDirectOrdersSalaryPaymentByOrder: state => state.directOrdersSalaryPaymentByOrder,
    getDirectOrdersHaveProposal: state => state.directOrdersHaveProposal,
    getDirectOrdersHaveNotProposal: state => state.directOrdersHaveNotProposal,
    getDirectOrdersWhoNeedRecommandation: state => state.directOrdersWhoNeedRecommandation,
  },
  mutations: {
    SET_CURRENT_BUSINESS_PACKAGE(state, val) {
      state.currentPackage = val
    },
    SET_PACKAGES(state, val) {
      state.packages = val
    },
    SET_ORDERS(state, val) {
      state.orders = val
    },
    SET_DIRECT_ORDERS_ACTIF(state, val) {
      state.directOrderActif = val
    },
    SET_CANCELED_ORDERS(state, val) {
      state.canceledOrders = val
    },
    SET_ORDER(state, val) {
      state.order = val
    },
    SET_TRANSACTIONS(state, val) {
      state.transactions = val
    },
    SET_CLIENT_BY_PORTEFEUILLE(state, val) {
      state.clientByPortefeuille = val
    },
    SET_ACTIVITY(state, val) {
      state.activity = val
    },
    SET_CUSTOMERS_MEET(state, val) {
      state.customersMeet = val
    },
    SET_AUTOMATIC_PAYMENT_VALIDATION(state, val) {
      state.automaticPaymentValidation = val
    },

    // Direct Mutations

    SET_DIRECT_ORDERS(state, val) {
      state.directOrders = val
    },
    SET_BUSINESS_ORDERS(state, val) {
      state.businessOrders = val
    },
    SET_BUSINESS_ORDERS_IN_PACKAGE(state, val) {
      state.businessOrdersInPackage = val
    },
    SET_DIRECT_ORDER_PAYMENT_HISTORY(state, val) {
      state.directOrdersPaymentHistory = val
    },
    SET_BUSINESS_ORDER_PAYMENT_HISTORY(state, val) {
      state.businessOrdersPaymentHistory = val
    },
    SET_DIRECT_ORDER_PAYMENT_EMPLOYEES(state, val) {
      state.directOrdersPaymentEmployees = val
    },
    SET_DIRECT_ORDER(state, val) {
      state.directOrder = val
    },
    SET_DIRECT_ORDER_PROPOSED_EMPLOYEES(state, val) {
      state.directOrderProposedEmployees = val
    },
    SET_TERMINATED_EMPLOYEES(state, val) {
      state.terminatedEmployees = val
    },
    SET_NOT_COMPLETED_DIRECT_ORDERS(state, val) {
      state.notCompletedDirectOrders = val
    },
    SET_ARCHIVED_DIRECT_ORDERS(state, val) {
      state.archivedDirectOrders = val
    },
    SET_ARCHIVED_BUSINESS_ORDERS(state, val) {
      state.archivedBusinessOrders = val
    },
    SET_DIRECT_ORDERS_SALARY_PAYMENT_BY_ORDER(state, val) {
      state.directOrdersSalaryPaymentByOrder = val
    },
    SET_DIRECT_ORDERS_HAVE_PROPOSAL(state, val) {
      state.directOrdersHaveProposal = val
    },
    SET_DIRECT_ORDERS_HAVE_NOT_PROPOSAL(state, val) {
      state.directOrdersHaveNotProposal = val
    },
    SET_DIRECT_ORDERS_WHO_NEED_RECOMMANDATION(state, val) {
      state.directOrdersWhoNeedRecommandation = val
    },
  },
  actions: {
    async totalBulkAction({ commit }, payload) {
      const response = await $service.totalBulkService(payload)
      return response.data
    },

    async getDirectOrdersActifAction({ commit }) {
      const response = await $service.getDirectOrdersActifService()
      commit('SET_DIRECT_ORDERS_ACTIF', response.data.data)
      return response.data
    },

    async filterDirectOrdersActifAction({ commit }, payload) {
      const response = await $service.filterDirectOrdersActifService(payload)
      return response.data
    },

    async loadMoreDirectOrdersActifAction({ commit }, url) {
      const response = await $service.getMoreDirectOrdersActifService(url)
      commit('SET_DIRECT_ORDERS_ACTIF', response.data.data)
      return response.data
    },
    async getOrdersAction({ commit }) {
      const response = await $service.getOrdersService()
      commit('SET_ORDERS', response.data.data)
      return response.data
    },
    async getPackagesAction({ commit }) {
      const response = await $service.getPackagesService()
      commit('SET_PACKAGES', response.data.data)
      return response.data
    },
    async loadMoreOrderAction({ commit }, url) {
      const response = await $service.getMoreOrderService(url)
      commit('SET_ORDERS', response.data.data)
      return response.data
    },

    async getCanceledOrdersAction({ commit }) {
      const response = await $service.getCanceledOrdersService()
      commit('SET_CANCELED_ORDERS', response.data.data)
      return response.data
    },

    async makeOfferAction({ commit }, { payload, orderId }) {
      const response = await $service.makeOfferService({ payload, orderId })
      return response.data
    },

    async deleteOrderAction({ commit }, id) {
      const response = await $service.deleteOrderService(id)
      return response
    },
    async resetOrderAction({ commit }, id) {
      const response = await $service.resetDirectOrderService(id)
      return response
    },

    async deleteOrderOfferAction({ commit }, id) {
      const response = await $service.deleteOrderOfferService(id)
      return response
    },

    async getOrderAction({ commit }, id) {
      const response = await $service.getOrderService(id)
      commit('SET_ORDER', response.data.data)
      return response.data
    },
    async getOrderOffersAction({ commit }, id) {
      const response = await $service.getOrderOffersService(id)
      return response.data
    },

    async finishOrderAction({ commit }, id) {
      const response = await $service.finishOrderService(id)
      return response.data
    },

    async getCustomerOrdersAction({ commit }, customerId) {
      const response = await $service.getCustomerOrdersService(customerId)
      return response.data
    },

    // Direct Actions

    async getBusinessOrdersAction({ commit }) {
      const response = await $service.getBusinessOrdersService()
      console.log(response.data.data)
      commit('SET_BUSINESS_ORDERS', response.data.data)
      return response.data
    },

    async getActifsBusinessEmployeesAction({ commit }, userId) {
      const response = await $service.getActifsBusinessEmployeesService(userId)
      return response.data
    },
    async advanceBusinessPaymentAction({ commit }, { payload, employeeId }) {
      const response = await $service.advanceBusinessPaymentActionService({ payload, employeeId })
      return response.data
    },
    async filterBusinessOrderInPackageAction({ commit }, {id, payload}){
      const response = await $service.filterBusinessOrderInPackageService(id, payload)
      console.log(response.data.data)
      commit('SET_BUSINESS_ORDERS', response.data.data)
      return response.data
    },

    async filterBusinessPackagesAction({ commit }, payload) {
      const response = await $service.filterBusinessPackagesService(payload)
      console.log(response.data.data)
      commit('SET_BUSINESS_ORDERS', response.data.data)
      return response.data
    },

    async filterBusinessPackagesCommandesAction({ commit }, payload) {
      const response = await $service.filterBusinessPackagesCommandesService(payload)
      console.log(response.data.data)
      commit('SET_BUSINESS_ORDERS', response.data.data)
      return response.data
    },
    async getBusinessOrderAction({ commit }, id) {
      const response = await $service.getBusinessOrderService(id)
      commit('SET_DIRECT_ORDER', response.data.data)
      return response.data
    },

    async autoAssignmentAction({ commit }, { packageId, payload }) {
      const response = await $service.autoAssignmentService({ packageId, payload })
      commit('SET_CURRENT_BUSINESS_PACKAGE', response.data.data)
      console.log(response.data.data)
      return response.data
    },

    async autoAssignmentRHAction({ commit }, { packageId, payload }) {
      const response = await $service.autoAssignmentRHService({ packageId, payload })
      commit('SET_CURRENT_BUSINESS_PACKAGE', response.data.data)
      console.log(response.data.data)
      return response.data
    },

    async assignAPackageToBusinessManagerAction(
      { commit },
      { packageId, payload },
    ) {
      const response = await $service.assignAPackageToBusinessManagerService({
        packageId,
        payload,
      })
      commit('SET_CURRENT_BUSINESS_PACKAGE', response.data.data)
      console.log(response.data.data)
      return response.data
    },

    async filterDirectOrderAction({ commit }, payload) {
      const response = await $service.filterDirectOrderService(payload)
      commit('SET_DIRECT_ORDERS', response.data.data)
      return response.data
    },
    async filterBusinessOrderAction({ commit }, payload) {
      const response = await $service.filterBusinessOrderService(payload)
      commit('SET_BUSINESS_ORDERS_IN_PACKAGE', response.data.data)
      return response.data
    },

    async loadMoreBusinessOrderAction({ commit }, url) {
      const response = await $service.getMoreBusinessOrderService(url)
      commit('SET_BUSINESS_ORDERS', response.data.data)
      return response.data
    },

    async filterPonctualOrderAction({ commit }, payload) {
      const response = await $service.filterPonctualOrderService(payload)
      commit('SET_ORDERS', response.data.data)
      return response.data
    },

    async loadMoreDirectOrderAction({ commit }, url) {
      const response = await $service.getMoreOrderService(url)
      commit('SET_DIRECT_ORDERS', response.data.data)
      return response.data
    },

    async getOrderInBusinessPackageAction({ commit }, id) {
      const response = await $service.getOrderInBusinessPackageService(id)
      commit('SET_BUSINESS_ORDERS_IN_PACKAGE', response.data.data)
      console.log(response.data.data)
      return response.data
    },
    async getOrderBusinessPackageByIdAction({ commit }, id) {
      const response = await $service.getOrderBusinessPackageByIdService(id)
      commit('SET_BUSINESS_ORDERS_IN_PACKAGE', response.data.data)
      return response.data
    },
    async loadMoreBusinessPackagesAction({ commit }, url) {
      const response = await $service.getMoreBusinessPackagesService(url)
      commit('SET_BUSINESS_ORDERS', response.data.data)
      return response.data
    },

    async modifyDirectOrderAction({ commit }, { id, payload }) {
      const response = await $service.modifyDirectOrderService({ id, payload })
      commit('SET_DIRECT_ORDER', response.data.data)
      return response.data
    },

    async modifyBusinessOrderAction({ commit }, { id, payload }) {
      const response = await $service.modifyBusinessOrderService({ id, payload })
      commit('SET_BUSINESS_ORDERS_IN_PACKAGE', response.data.data)
      return response.data
    },

    async getDirectOrderProposedEmployeesAction({ commit }, id) {
      const response = await $service.getDirectOrderProposedEmployeesService(
        id,
      )
      commit('SET_DIRECT_ORDER_PROPOSED_EMPLOYEES', response.data.data)
      return response.data
    },

    async getDirectOrdersSalaryPaymentsByOrderAction({ commit }, id) {
      const response = await $service.getDirectOrdersSalaryPaymentsByOrderService(
        id,
      )
      commit('SET_DIRECT_ORDERS_SALARY_PAYMENT_BY_ORDER', response.data)
      return response.data
    },

    async deleteDirectOrderAction({ commit }, id) {
      const response = await $service.deleteDirectOrderService(id)
      return response
    },

    async deleteBusinessOrderAction({ commit }, id) {
      const response = await $service.deleteBusinessOrderService(id)
      return response
    },

    async makeEmployeeRecommandationAction({ commit }, payload) {
      const response = await $service.makeEmployeeRecommandationService(payload)
      return response.data
    },

    async makeEmployeeResiliationAction({ commit }, { payload, orderId }) {
      const response = await $service.makeEmployeeResiliationService({ payload, orderId })
      return response.data
    },

    async downloadPaymentAction({ commit }, payload) {
      const response = await $service.downloadPaymentService(payload)
      return response.data
    },

    async makeEmployeeAffectationAction({ commit }, { payload, id }) {
      const response = await $service.makeEmployeeAffectationService({ payload, id })
      return response.data
    },

    async makePropositionAction({ commit }, { payload, id }) {
      const response = await $service.makePropositionService({ payload, id })
      return response
    },

    async deleteDirectOrderPropositionAction(
      { commit },
      { directOrderId, employeeId },
    ) {
      const response = await $service.deleteDirectOrderPropositionService({
        directOrderId,
        employeeId,
      })
      return response
    },

    async terminateEmployeeContractAction({ commit }, { propositionId, payload }) {
      const response = await $service.terminateEmployeeContractService({
        propositionId,
        payload,
      })
      return response.data
    },

    async markDirectOrderAsFinishedAction({ commit }, { packageId, payload }) {
      const response = await $service.markDirectOrderAsFinishedService({ packageId, payload })
      return response.data
    },
    async markUnterminateContractAction({ commit }, packageId) {
      const response = await $service.markUnterminateContractService(packageId)
      return response.data
    },

    async makeBusinessOrderForAClientAction({ commit }, payload) {
      const response = await $service.makeBusinessOrderForAClientService(payload)
      console.log(payload)
      return response.data
    },

    async makeEmployeeDeploymentAction({ commit }, { propositionId, payload }) {
      const response = await $service.makeEmployeeDeploymentService({
        propositionId,
        payload,
      })
      return response.data
    },

    async makeBusinessEmployeeDeploymentAction({ commit }, { orderId, payload }) {
      const response = await $service.makeBusinessEmployeeDeploymentService({
        orderId,
        payload,
      })
      return response.data
    },

    async makeApplyCnssAction({ commit }, { payload }) {
      const response = await $service.makeApplyCnssService({
        payload,
      })
      return response.data
    },

    async changeEmployeeDeploymentAction({ commit }, { propositionId, payload }) {
      const response = await $service.changeEmployeeDeploymentDateService({
        propositionId,
        payload,
      })
      return response.data
    },

    async changeBusinessEmployeeDeploymentAction({ commit }, { propositionId, payload }) {
      const response = await $service.changeBusinessEmployeeDeploymentDateService({
        propositionId,
        payload,
      })
      return response.data
    },
    async getTerminatedEmployeesAction({ commit }, orderId) {
      const response = await $service.getTerminatedEmployeesService(orderId)
      commit('SET_TERMINATED_EMPLOYEES', response.data.data)
      return response.data
    },

    async getRecommendedEmployeesAction({ commit }, orderId) {
      const response = await $service.getRecommendedEmployeesService(orderId)
      return response.data
    },

    async getAffectedEmployeesAction({ commit }, orderId) {
      const response = await $service.getAffectedEmployeesService(orderId)
      return response.data
    },

    async getActifEmployeesAction({ commit }, orderId) {
      const response = await $service.getActifEmployeesService(orderId)
      return response.data
    },

    async sendFeedbackAction({ commit }, { orderId, payload }) {
      const response = await $service.sendFeedbackService({ orderId, payload })
      return response.data
    },

    async modifyPropositionAction(
      { commit },
      { payload, id, proposedEmployeeId },
    ) {
      const response = await $service.modifyPropositionService({
        payload,
        id,
        proposedEmployeeId,
      })
      return response
    },

    async modifyOfferAction({ commit }, { updatePayload, offerId }) {
      console.log('payload', updatePayload)
      console.log('offerId', offerId)

      const response = await $service.modifyOfferService({ updatePayload, offerId })
      return response.data
    },

    async modifyRecommandationAction(
      { commit },
      { orderId, employeeRecommendationId, payload },
    ) {
      const response = await $service.modifyRecommendationService({
        employeeRecommendationId,
        payload,
      })
      return response
    },

    async modifyAffectationAction(
      { commit },
      { orderId, employeeAffectedId, payload },
    ) {
      const response = await $service.modifyAffectationService({
        employeeAffectedId,
        payload,
      })
      return response
    },

    async assignAnBusinessOrderToCaAction(
      { commit },
      { orderId, payload },
    ) {
      return await $service.assignAnBusinessOrderToCaService({
        orderId,
        payload,
      })
    },

    async assignAnBusinessOrderToARHAction(
      { commit },
      { orderId, payload },
    ) {
      return await $service.assignAnBusinessOrderToARHService({
        orderId,
        payload,
      })
    },

    async getDirectOrdersAssignedToBusinessManagerAction(
      { commit },
      businessManagerId,
    ) {
      const response = await $service.getDirectOrdersAssignedToBusinessManagerService(
        businessManagerId,
      )
      commit('SET_DIRECT_ORDERS', response.data.data)
      return response.data
    },

    async filterDirectOrdersSalaryPaymentsAction({ commit }, payload) {
      const response = await $service.filterDirectOrdersSalaryPaymentsService(payload)
      commit('SET_DIRECT_ORDER_PAYMENT_HISTORY', response.data.data)
      return response.data
    },

    async filterBusinessOrdersSalaryPaymentsAction({ commit }, payload) {
      const response = await $service.filterBusinessOrdersSalaryPaymentsService(payload)
      commit('SET_BUSINESS_ORDER_PAYMENT_HISTORY', response.data.data)
      return response.data
    },

    async getDirectOrdersSalaryPaymentsAction({ commit }) {
      const response = await $service.getDirectOrdersSalaryPaymentsService()
      commit('SET_DIRECT_ORDER_PAYMENT_HISTORY', response.data.data)
      return response.data
    },

    async getBusinessOrdersSalaryPaymentsAction({ commit }) {
      const response = await $service.getBusinessOrdersSalaryPaymentsService()
      commit('SET_BUSINESS_ORDER_PAYMENT_HISTORY', response.data.data)
      console.log(response.data)
      return response.data
    },

    async loadMoreDirectOrderPaymentHistoryAction({ commit }, url) {
      const response = await $service.getMoreOrderService(url)
      commit('SET_DIRECT_ORDER_PAYMENT_HISTORY', response.data.data)
      return response.data
    },

    async loadMoreBusinessOrderPaymentHistoryAction({ commit }, url) {
      const response = await $service.getMoreOrderService(url)
      commit('SET_BUSINESS_ORDER_PAYMENT_HISTORY', response.data.data)
      return response.data
    },

    async filterDirectOrdersSalaryEmployeesAction({ commit }, payload) {
      const response = await $service.filterDirectOrdersSalaryEmployeesService(payload)
      commit('SET_DIRECT_ORDER_PAYMENT_EMPLOYEES', response.data.data)
      return response.data
    },

    async getDirectOrdersSalaryEmployeesAction({ commit }) {
      const response = await $service.getDirectOrdersSalaryEmployeesService()
      commit('SET_DIRECT_ORDER_PAYMENT_EMPLOYEES', response.data.data)
      return response.data
    },

    async loadMoreDirectOrderPaymentEmployeesAction({ commit }, url) {
      const response = await $service.getMoreOrderService(url)
      commit('SET_DIRECT_ORDER_PAYMENT_EMPLOYEES', response.data.data)
      return response.data
    },

    async getNotCompletedDirectOrdersAction({ commit }) {
      const response = await $service.getNotCompletedDirectOrdersService()
      commit('SET_NOT_COMPLETED_DIRECT_ORDERS', response.data.data)
      return response.data
    },

    async loadMoreNotCompletedDirectOrderAction({ commit }, url) {
      const response = await $service.getMoreOrderService(url)
      commit('SET_NOT_COMPLETED_DIRECT_ORDERS', response.data.data)
      return response.data
    },

    async takeOverAction({ commit }, orderId) {
      const response = await $service.takeOverService(orderId)
      return response.data
    },

    async deleteFailedDirectOrderAction({ commit }, orderId) {
      return $service.deleteFailedDirectOrderService(orderId)
    },

    async cancelDirectOrderPaymentAction({ commit }, paymentId) {
      return $service.cancelOrderService(paymentId)
    },

    async archiveDirectOrderAction({ commit }, { directOrderId, payload }) {
      const response = await $service.archiveDirectOrderService({ directOrderId, payload })
      return response
    },

    async archiveBusinessOrderAction({ commit }, { directOrderId, payload }) {
      const response = await $service.archiveBusinessOrderService({ directOrderId, payload })
      return response
    },

    async updateBusinessOrderArchivingAction({ commit }, { directOrderId, payload }) {
      const response = await $service.updateBusinessOrderArchivingService({ directOrderId, payload })
      return response
    },

    async updateDirectOrderArchivingAction({ commit }, { directOrderId, payload }) {
      const response = await $service.updateDirectOrderArchivingService({ directOrderId, payload })
      return response
    },

    async updateBusinessOrderArchivingAction({ commit }, { directOrderId, payload }) {
      const response = await $service.updateBusinessOrderArchivingService({ directOrderId, payload })
      return response
    },

    async getArchivedDirectOrdersAction({ commit }) {
      const response = await $service.getArchivedDirectOrdersService()
      commit('SET_ARCHIVED_DIRECT_ORDERS', response.data.data)
      return response.data
    },
    async filterArchivedDirectOrdersAction({ commit }, payload) {
      const response = await $service.filterArchivedDirectOrdersService(payload)
      commit('SET_ARCHIVED_DIRECT_ORDERS', response.data.data)
      return response.data
    },
    async loadMoreArchivedDirectOrderAction({ commit }, url) {
      const response = await $service.getMoreOrderService(url)
      commit('SET_ARCHIVED_DIRECT_ORDERS', response.data.data)
      return response.data
    },

    async unarchiveDirectOrderAction({ commit }, orderId) {
      const response = await $service.unarchiveDirectOrderService(orderId)
      return response
    },

    async getArchivedBusinessOrdersAction({ commit }) {
      const response = await $service.getArchivedBusinessOrdersService()
      commit('SET_ARCHIVED_BUSINESS_ORDERS', response.data.data)
      return response.data
    },

    async unarchiveBusinessOrderAction({ commit }, orderId) {
      const response = await $service.unarchiveBusinessOrderService(orderId)
      return response
    },

    async filterArchivedBusinessOrdersAction({ commit }, payload) {
      const response = await $service.filterArchivedBusinessOrdersService(payload)
      commit('SET_ARCHIVED_BUSINESS_ORDERS', response.data.data)
      return response.data
    },

    async loadMoreArchivedBusinessOrderAction({ commit }, url) {
      const response = await $service.getMoreOrderService(url)
      commit('SET_ARCHIVED_BUSINESS_ORDERS', response.data.data)
      return response.data
    },

    async loadMoreDirectOrdersWhoNeedRecommandationAction({ commit }, url) {
      const response = await $service.loadMoreDirectOrdersWhoNeedRecommandation(url)
      const orderWithRecommandationDone = []
      const orderWithRecommandationNotDone = []
      response.data.results.data.forEach(element => {
        if (element.recommended_employees_count) {
          orderWithRecommandationDone.push(element)
        } else {
          orderWithRecommandationNotDone.push(element)
        }
      })
      commit(
        'SET_DIRECT_ORDERS_HAVE_NOT_PROPOSAL',
        orderWithRecommandationNotDone,
      )
      commit('SET_DIRECT_ORDERS_HAVE_PROPOSAL', orderWithRecommandationDone)
      commit('SET_DIRECT_ORDERS_WHO_NEED_RECOMMANDATION', response.data.results)
      return response.data
    },

    async getDirectOrdersWhoNeedRecommandationAction({ commit }) {
      const response = await $service.getDirectOrdersWhoNeedRecommandation()
      const orderWithRecommandationDone = []
      const orderWithRecommandationNotDone = []
      response.data.results.data.forEach(element => {
        if (element.recommended_employees_count) {
          orderWithRecommandationDone.push(element)
        } else {
          orderWithRecommandationNotDone.push(element)
        }
      })
      commit(
        'SET_DIRECT_ORDERS_HAVE_NOT_PROPOSAL',
        orderWithRecommandationNotDone,
      )
      commit('SET_DIRECT_ORDERS_HAVE_PROPOSAL', orderWithRecommandationDone)
      commit('SET_DIRECT_ORDERS_WHO_NEED_RECOMMANDATION', response.data.results)
      return response.data
    },

    async filterDirectOrdersWhoNeedRecommandationAction({ commit }, payload) {
      const response = await $service.filterDirectOrdersWhoNeedRecommandationAction(payload)
      const orderWithRecommandationDone = []
      const orderWithRecommandationNotDone = []
      response.data.results.data.forEach(element => {
        if (element.recommended_employees_count) {
          orderWithRecommandationDone.push(element)
        } else {
          orderWithRecommandationNotDone.push(element)
        }
      })
      commit(
        'SET_DIRECT_ORDERS_HAVE_NOT_PROPOSAL',
        orderWithRecommandationNotDone,
      )
      commit('SET_DIRECT_ORDERS_HAVE_PROPOSAL', orderWithRecommandationDone)
      commit('SET_DIRECT_ORDERS_WHO_NEED_RECOMMANDATION', response.data.results)
      return response.data
    },
    async getSalaryPayementDetailsAction({ commit }, { packageId, payload }) {
      const response = await $service.getSalaryPayementDetailsServices({ packageId, payload })
      return response.data
    },

    async getBusinessSalaryPayementDetailsAction({ commit }, payload) {
      const response = await $service.getBusinessSalaryPayementDetailsServices(payload)
      return response.data
    },
    async confirmSalaryCumulPaymentAction({ commit }, { packageId, payload }) {
      const response = await $service.confirmSalaryCumulPaymentServices({ packageId, payload })
      return response.data
    },
    async cancelCumulPaymentAction({ commit }, { packageId, payload }) {
      const response = await $service.cancelCumulPaymentServices({ packageId, payload })
      return response.data
    },
    async confirmSingularSalaryPaymentAction({ commit }, paymentId) {
      const response = await $service.confirmSingularSalaryPaymentServices(paymentId)
      return response.data
    },
    async cancelSingularSalaryPaymentAction({ commit }, paymentId) {
      const response = await $service.cancelSingularSalaryPaymentServices(paymentId)
      return response.data
    },
    async cancelUnpaidPaymentAction({ commit }, paymentId) {
      const response = await $service.cancelUnpaidPaymentServices(paymentId)
      return response.data
    },
    async sendDeleteRequestAction({ commit }, { orderId, payload }) {
      const response = await $service.sendDeleteRequestService({ orderId, payload })
      return response.data
    },
    async storeCommentAction({ commit }, payload) {
      const response = await $service.storeCommentServices(payload)
      return response.data
    },
    async getCommentsAction({ commit }, orderId) {
      const response = await $service.getCommentsServices(orderId)
      return response.data
    },
    async deleteCommentAction({ commit }, commentId) {
      const response = await $service.deleteCommentServices(commentId)
      return response.data
    },
    async updateCommentAction({ commit }, { commentId, payload }) {
      const response = await $service.updateCommentServices({ commentId, payload })
      return response.data
    },
     async reactiveEmployeeAction({ commit }, {propositionId, payload}) {
      const response = await $service.reactiveEmployeeServices({propositionId, payload})
      return response.data
    },

    async transfertOrderTobusinessAction({ commit }, orderId) {
      const response = await $service.transfertOrderTobusinessService(orderId)
      return response.data
    },

     async getTransactionsAction({ commit }) {
      const response = await $service.getTransactionsServices()
      commit('SET_TRANSACTIONS', response.data.data)
      return response.data
    },
    async filterTransactionsAction({ commit }, payload) {
      const response = await $service.filterTransactionsServices(payload)
      return response.data
    },
    async loadMoreTransactionsAction({ commit }, url) {
      const response = await $service.getMoreTransactionsService(url)
      commit('SET_TRANSACTIONS', response.data.data)
      return response.data
    },

    async getClientByPortefeuilleAction({ commit }) {
      const response = await $service.getClientByPortefeuilleServices()
      commit('SET_CLIENT_BY_PORTEFEUILLE', response.data.data)
      console.log(response.data.data.data)
      return response.data
    },
    async filterClientByPortefeuilleAction({ commit }, payload) {
      const response = await $service.filterClientByPortefeuilleServices(payload)
      return response.data
    },
    async getCustomersMeetAction({ commit }) {
      const response = await $service.getCustomersMeetServices()
      commit('SET_CUSTOMERS_MEET', response.data.data)
      console.log(response.data.data)
      return response.data.data
    },
    async filterCustomersMeetAction({ commit }, payload) {
      const response = await $service.filterCustomersMeetServices(payload)
      return response.data
    },
    async loadMoreCustomersMeetAction({ commit }, url) {
      const response = await $service.getMoreCustomersMeetService(url)
      commit('SET_CUSTOMERS_MEET', response.data.data)
      return response.data
    },
    async loadMoreClientByPortefeuilleAction({ commit }, url) {
      const response = await $service.getMoreClientByPortefeuilleService(url)
      commit('SET_CLIENT_BY_PORTEFEUILLE', response.data.data)
      return response.data
    },
    async getActivityAction({ commit }) {
      const response = await $service.getActivityServices()
      commit('SET_ACTIVITY', response.data.data)
      return response.data.data
    },
    async loadMoreActivityAction({ commit }, url) {
      const response = await $service.getMoreActivityService(url)
      commit('SET_ACTIVITY', response.data.data)
      return response.data
    },
    async meetValidationAction({ commit }, meetId) {
      const response = await $service.meetValidationServices(meetId)
      return response.data
    },
    async changeMeetAction({ commit }, { meetId, payload }) {
      const response = await $service.changeMeetServices({ meetId, payload })
      return response.data
    },

    async automaticPaymentValidationAction({ commit }, packageId) {
      const response = await $service.automaticPaymentValidationServices(packageId)
      commit('SET_AUTOMATIC_PAYMENT_VALIDATION', response.data)
      return response.data
    },
    async desactivateAutomaticPaymentAction({ commit }, packageId) {
      const response = await $service.desactivateAutomaticPaymentServices(packageId)
      return response.data
    },
    async getUnpaidOrdersAction({ commit }, packageId) {
      const response = await $service.getUnpaidOrdersServices(packageId)
      return response.data
    },
    async filterCnssAction({ commit }, payload) {
      const response = await $service.filterCnssServices(payload)
      return response.data
    },

    async confirmOfferPaymentAction({ commit }, {
      orderId, offerId, full, amount,
    }) {
      const response = await $service.confirmOfferPaymentServices({
        orderId, offerId, full, amount,
      })
      return response
    },

    async businessEmployeeSalaryUnpaidAction({ commit }) {
      const response = await $service.businessEmployeeSalaryUnpaidServices()
      return response.data
    },

    async businessUnpaidEmployeesAction({ commit }, userId) {
      const response = await $service.businessUnpaidEmployeesServices(userId)
      return response.data
    },

    async markSalaryPaymentAction({ commit }, payload) {
      const response = await $service.markSalaryPaymentServices(payload)
      return response.data
    },

    async employeeResiliationAction({ commit }, payload) {
      const response = await $service.employeeResiliationServices(payload)
      return response.data
    },
  },

}
