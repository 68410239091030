/* eslint-disable no-unused-vars */
// eslint-disable-next-line import/no-cycle
import $service from '@/callApi/categories'

export default {
  namespaced: true,
  state: {
    categories: [],
  },
  getters: {
    getCategories: state => state.categories,
  },
  mutations: {
    SET_CATEGORIES(state, val) {
      state.categories = val
    },
  },
  actions: {

    async getCategoriesAction({ commit }) {
      const response = await $service.getCategoriesService()
      commit('SET_CATEGORIES', response.data.data)
      return response.data
    },

    async addCategoryAction({ commit }, payload) {
      const response = await $service.addCategoryService(payload)
      return response.data
    },

    async updateCategoryAction({ commit }, { payload, id }) {
      const response = await $service.updateCategoryService(payload, id)
      return response.data
    },

    async deleteCategoryAction({ commit }, id) {
      const response = await $service.deleteCategoryService(id)
      return response
    },

  },
}
