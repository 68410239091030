import $service from '@/callApi/access'

export default {
  namespaced: true,
  state: {
    roles: [],
  },
  getters: {
    getRoles: state => state.roles,
  },
  mutations: {
    SET_ROLES(state, val) {
      state.roles = val
    },
  },
  actions: {

    async getRolesAction({ commit }) {
      const response = await $service.getRolesService()
      commit('SET_ROLES', response.data.data)
      return response.data
    },

    async addNewRoleAction({ commit }, payload) {
      const response = await $service.addNewRoleService(payload)
      return response.data
    },

    async updateRoleAction({ commit }, { payload, id }) {
      const response = await $service.updateRoleService(payload, id)
      return response.data
    },

    async deleteRoleAction({ commit }, id) {
      const response = await $service.deleteRoleService(id)
      return response
    },
  },
}
