<template>
  <div class="w-100 text-center">
    <h2 class="mb-1 font-large-5">
      🔐
    </h2>
    <p class="mb-2 font-medium-2 text-warning">
      Vous n'êtes pas autorisé à voir ces détails!
    </p>
  </div>
</template>

<script>
export default {
  name: "NotAuthorized"
}
</script>

<style scoped>

</style>