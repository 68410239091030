import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import $service from '@/callApi/global'
import app from './app'
import auth from './auth'
import users from './users'
import access from './access'
import services from './services'
import categories from './categories'
import orders from './orders'
import professionals from './professionals'
import customers from './customers'
import formation from './formation'
import statistics from './statistics'
import roles from './roles'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import offerType from '@/store/offer-type'
import suivis from '@/store/suivis'
import blog from '@/store/blog'
import packages from '@/store/packages'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    orderDetailsTabIsActive: true,
    orderProposedTabIsActive: false,
    orderDesistementTabIsActive: false,
    orderPaymentTabIsActive: false,
    isCategoriesLoading: true,
    isProAmountLoading: true,
    isCommissionLoading: true,
    isStatisticsLoading: true,
    isDateFinanceStatisticsLoading: true,
    isNumbersStatisticsLoading: true,
    isTauxStatisticsLoading: true,
    isRHStatisticsLoading: true,
    isMonthFinanceStatisticsLoading: true,
    isSatisfiedOrderLoading: true,
    isUnsatisfiedOrderLoading: true,
    isServicesLoading: true,
    isCustomerCommisionLoading: true,
    isOrdersLoading: true,
    isCanceledOrdersLoading: true,
    isProfessionalsLoading: true,
    isCustomersLoading: true,
    isCustomersWithoutPaginationLoading: true,
    isBlogCatetegoriesLoading : true,
    isBlogArticlesLoading: true,
    isProfessionalsWithoutPaginationLoading: true,
    isPointFocauxWihoutPagination: true,
    isEmployeesWithoutPaginationLoading: true,
    isServicesWithoutPaginationLoading: true,
    isServicesRecurringWithoutPaginationLoading: true,
    isEmployeeGestionLoading: true,
    isActifEmployeesLoading: true,

    isProPerAddressAndServiceLoading: true,
    isProAmountPerServiceLoading: true,
    isCustomersInDeptLoading: true,

    isPonctualOrdersFilterLoading: true,

    // Direct States
    isDirectPackagesLoading: true,
    clientByPortefeuille: true,
    isDirectPackagesLoading: true,
    isTransactionsLoading: true,
    isClientByPortefeuilleLoading: true,
    isActivityLoading: true,
    isCustomersMeetLoading: true,
    isSuivisLoading: true,
    isPackageClicked: false,
    isDirectOrdersLoading: true,
    isBusinessOrdersLoading: true,
    isBusinessOrdersInPackageLoading: true,
    isDirectOrdersFilterLoading: true,
    isRecurringServicesLoading: true,
    isMostRequestedRecurringServicesLoading: true,
    isMostRequestedServicesLoading: true,
    isEmployeesLoading: true,
    isPaymentHistoryLoading: true,
    isBusinessPaymentHistoryLoading: true,
    isPaymentEmployeesLoading: true,
    isPayedProsLoading: true,
    isUnpayedProsLoading: true,
    isEmployeesWalletLoading: true,
    isEmployeesWalletsLogsLoading: true,
    isBusinessManagersListLoading: true,
    isARHListLoading: true,

    isAdminOrderListLoading: true,
    isDirectOrdersActifLoading: true,
    isBusinessDeveloperListLoading: true,
    isAssistantRHListLoading: true,
    isPointsFocauxListLoading: true,
    isNotificationListLoading: true,

    isNotCompletedDirectOrdersLoading: true,
    isArchivedDirectOrdersLoading: true,
    isArchivedBusinessOrdersLoading: true,
    isUnformedEmployeesLoading: true,
    isUnformedEmployeesListLoading: true,

    isDirectOrdersHaveProposalLoading: true,
    isDirectOrdersHaveNotProposalLoading: true,

    isDirectOrdersWhoNeedRecommandationLoading: true,
    isDirectPackageOrdersLoading: true,
    isBusinessPackageOrdersLoading: true,

    pointsFocauxList: [],
  },

  getters: {
    isPackageClicked: state => state.isPackageClicked,
    isActifEmployeesLoading: state => state.isActifEmployeesLoading,
    orderDetailsTabIsActive: state => state.orderDetailsTabIsActive,
    orderProposedTabIsActive: state => state.orderProposedTabIsActive,
    orderDesistementTabIsActive: state => state.orderDesistementTabIsActive,
    orderPaymentTabIsActive: state => state.orderPaymentTabIsActive,
    isDirectOrdersActifLoading: state => state.isDirectOrdersActifLoading,
    isSuivisLoading: state => state.isSuivisLoading,
    isServicesLoading: state => state.isServicesLoading,
    isSatisfiedOrderLoading: state => state.isSatisfiedOrderLoading,
    isProAmountLoading: state => state.isProAmountLoading,
    isCommissionLoading: state => state.isCommissionLoading,
    isStatisticsLoading: state => state.isStatisticsLoading,
    isDateFinanceStatisticsLoading: state => state.isDateFinanceStatisticsLoading,
    isNumbersStatisticsLoading: state => state.isNumbersStatisticsLoading,
    isTauxStatisticsLoading: state => state.isTauxStatisticsLoading,
    isRHStatisticsLoading: state => state.isRHStatisticsLoading,
    isMonthFinanceStatisticsLoading: state => state.isMonthFinanceStatisticsLoading,
    isProfessionalsWithoutPaginationLoading: state => state.isProfessionalsWithoutPaginationLoading,
    isPointFocauxWihoutPagination: state => state.isPointFocauxWihoutPagination,
    isUnsatisfiedOrderLoading: state => state.isUnsatisfiedOrderLoading,
    isCategoriesLoading: state => state.isCategoriesLoading,
    isOrdersLoading: state => state.isOrdersLoading,
    isCanceledOrdersLoading: state => state.isCanceledOrdersLoading,
    isProfessionalsLoading: state => state.isProfessionalsLoading,
    isCustomersLoading: state => state.isCustomersLoading,
    isCustomersWithoutPaginationLoading: state => state.isCustomersWithoutPaginationLoading,
    isBlogCatetegoriesLoading: state => state.isBlogCatetegoriesLoading,
    isBlogArticlesLoading:state => state.isBlogArticlesLoading,
    isEmployeesWithoutPaginationLoading: state => state.isEmployeesWithoutPaginationLoading,
    isServicesWithoutPaginationLoading: state => state.isServicesWithoutPaginationLoading,
    isServicesRecurringWithoutPaginationLoading: state => state.isServicesRecurringWithoutPaginationLoading,
    isEmployeeGestionLoading: state => state.isEmployeeGestionLoading,

    isCustomerCommisionLoading: state => state.isCustomerCommisionLoading,
    isProPerAddressAndServiceLoading: state => state.isProPerAddressAndServiceLoading,
    isProAmountPerServiceLoading: state => state.isProAmountPerServiceLoading,
    isCustomersInDeptLoading: state => state.isCustomersInDeptLoading,

    // Direct Getters
    isDirectPackagesLoading: state => state.isDirectPackagesLoading,
    isTransactionsLoading: state => state.isTransactionsLoading,
    isClientByPortefeuilleLoading: state => state.isClientByPortefeuilleLoading,
    isActivityLoading: state => state.isActivityLoading,
    isCustomersMeetLoading: state => state.isCustomersMeetLoading,
    isDirectPackageOrdersLoading: state => state.isDirectPackageOrdersLoading,
    isBusinessPackageOrdersLoading: state => state.isBusinessPackageOrdersLoading,
    isDirectOrdersLoading: state => state.isDirectOrdersLoading,
    isBusinessOrdersLoading: state => state.isBusinessOrdersLoading,
    isBusinessOrdersInPackageLoading: state => state.isBusinessOrdersInPackageLoading,
    isRecurringServicesLoading: state => state.isRecurringServicesLoading,
    isMostRequestedRecurringServicesLoading: state => state.isMostRequestedRecurringServicesLoading,
    isMostRequestedServicesLoading: state => state.isMostRequestedServicesLoading,
    isEmployeesLoading: state => state.isEmployeesLoading,
    isPayedProsLoading: state => state.isPayedProsLoading,
    isUnpayedProsLoading: state => state.isUnpayedProsLoading,
    isEmployeesWalletLoading: state => state.isEmployeesWalletLoading,

    isEmployeesWalletsLogsLoading: state => state.isEmployeesWalletsLogsLoading,

    isBusinessManagersListLoading: state => state.isBusinessManagersListLoading,
    isARHListLoading: state => state.isARHListLoading,
    isAdminOrderListLoading: state => state.isAdminOrderListLoading,
    isBusinessDeveloperListLoading: state => state.isBusinessDeveloperListLoading,
    isAssistantRHListLoading: state => state.isAssistantRHListLoading,

    isPointsFocauxListLoading: state => state.isPointsFocauxListLoading,
    isNotificationListLoading: state => state.isNotificationListLoading,


    isNotCompletedDirectOrdersLoading: state => state.isNotCompletedDirectOrdersLoading,
    isArchivedDirectOrdersLoading: state => state.isArchivedDirectOrdersLoading,
    isArchivedBusinessOrdersLoading: state => state.isArchivedBusinessOrdersLoading,
    isPaymentHistoryLoading: state => state.isPaymentHistoryLoading,
    isBusinessPaymentHistoryLoading: state => state.isBusinessPaymentHistoryLoading,
    isPaymentEmployeesLoading: state => state.isPaymentEmployeesLoading,
    isUnformedEmployeesLoading: state => state.isUnformedEmployeesLoading,

    isDirectOrdersHaveProposalLoading: state => state.isDirectOrdersHaveProposalLoading,
    isDirectOrdersHaveNotProposalLoading: state => state.isDirectOrdersHaveNotProposalLoading,

    isDirectOrdersWhoNeedRecommandationLoading: state => state.isDirectOrdersWhoNeedRecommandationLoading,
    getPointsFocauxList: state => state.pointsFocauxList,
  },

  mutations: {
    SET_IS_PACKAGE_CLICKED(state, val) {
      state.isPackageClicked = val
    },
     SET_IS_ACTIF_EMPLOYEES(state, val) {
      state.isActifEmployeesLoading = val
    },
    SET_IS_CANCELED_ORDER_LOADING(state, val) {
      state.isCanceledOrdersLoading = val
    },
    SET_IS_EMPLOYEE_WALLET_LOADING(state, val) {
      state.isEmployeesWalletLoading = val
    },

    SET_IS_EMPLOYEES_WALLETS_LOGS_LOADING(state, val) {
      state.isEmployeesWalletsLogsLoading = val
    },

    SET_IS_PAYMENT_HISTORY_LOADING(state, val) {
      state.isPaymentHistoryLoading = val
    },
    SET_IS_BUSINESS_PAYMENT_HISTORY_LOADING(state, val) {
      state.isBusinessPaymentHistoryLoading = val
    },
    
    SET_IS_PAYMENT_EMPLOYEES_LOADING(state, val) {
      state.isPaymentEmployeesLoading = val
    },

    SET_PRO_AMOUNT_PER_SERVICE_LOADING(state, val) {
      state.isProAmountPerServiceLoading = val
    },

    SET_IS_CATEGORIES_LOADING(state, val) {
      state.isCategoriesLoading = val
    },

    SET_IS_CUSTOMERS_COMMISION_LOADING(state, val) {
      state.isCustomerCommisionLoading = val
    },

    SET_IS_COMMISSION_LOADING(state, val) {
      state.isCommissionLoading = val
    },

    SET_IS_SATISFIED_ORDER_LOADING(state, val) {
      state.isSatisfiedOrderLoading = val
    },

    SET_IS_UNSATISFIED_ORDER_LOADING(state, val) {
      state.isUnsatisfiedOrderLoading = val
    },

    SET_IS_PRO_AMOUNT_LOADING(state, val) {
      state.isProAmountLoading = val
    },

    SET_IS_PRO_PER_ADDRESS__AND_SERVICE_LOADING(state, val) {
      state.isProPerAddressAndServiceLoading = val
    },

    SET_IS_SERVICES_LOADING(state, val) {
      state.isServicesLoading = val
    },

    SET_IS_ORDERS_LOADING(state, val) {
      state.isOrdersLoading = val
    },

    SET_IS_CUSTOMERS_LOADING(state, val) {
      state.isCustomersLoading = val
    },
    
    SET_IS_CUSTOMERS_WITHOUT_PAGINATION_LOADING(state, val) {
      state.isCustomersWithoutPaginationLoading = val
    },
    SET_IS_BLOGS_CATEGORIES_LOADING(state, val) {
      state.isBlogCatetegoriesLoading = val
    },
    SET_IS_BLOGS_ARTICLES_LOADING(state, val) {
      state.isBlogArticlesLoading = val
    },
    SET_IS_PROFESSIONALS_WITHOUT_PAGINATION_LOADING(state, val) {
      state.isProfessionalsWithoutPaginationLoading = val
    },
    SET_IS_POINT_FOCAUX_WITHOUT_PAGINATION_LOADING(state, val) {
      state.isPointFocauxWihoutPagination = val
    },
    SET_IS_DIRECT_ORDERS_ACTIF_LOADING(state, val) {
      state.isDirectOrdersActifLoading = val
    },
    SET_IS_SUIVIS_LOADING(state, val) {
      state.isSuivisLoading = val
    },
    SET_IS_EMPLOYEES_WITHOUT_PAGINATION_LOADING(state, val) {
      state.isEmployeesWithoutPaginationLoading = val
    },

    SET_IS_SERVICES_WITHOUT_PAGINATION_LOADING(state, val) {
      state.isServicesWithoutPaginationLoading = val
    },

    SET_IS_SERVICES_RECURRING_WITHOUT_PAGINATION_LOADING(state, val) {
      state.isServicesRecurringWithoutPaginationLoading = val
    },
    SET_IS_EMPLOYEE_GESTION_LOADING(state, val) {
      state.isEmployeeGestionLoading = val
    },


    SET_IS_PROFESSIONALS_LOADING(state, val) {
      state.isProfessionalsLoading = val
    },

    SET_IS_STATISTICS_LOADING(state, val) {
      state.isStatisticsLoading = val
    },

    SET_IS_DATE_FINANCE_STATISTICS_LOADING(state, val) {
      state.isDateFinanceStatisticsLoading = val
    },
    SET_IS_NUMBERS_STATISTICS_LOADING(state, val) {
      state.isNumbersStatisticsLoading = val
    },
    SET_IS_TAUX_STATISTICS_LOADING(state, val) {
      state.isTauxStatisticsLoading = val
    },
    SET_IS_RH_STATISTICS_LOADING(state, val) {
      state.isRHStatisticsLoading = val
    },

    SET_IS_MONTH_FINANCE_STATISTICS_LOADING(state, val) {
      state.isMonthFinanceStatisticsLoading = val
    },

    SET_IS_CUSTOMERS_IN_DEPT_LOADING(state, val) {
      state.isCustomersInDeptLoading = val
    },

    SET_IS_DIRECT_ORDERS_FILTER_LOADING(state, val) {
      state.isDirectOrdersFilterLoading = val
    },
    SET_IS_PONCTUAL_ORDERS_FILTER_LOADING(state, val) {
      state.isPonctualOrdersFilterLoading = val
    },
    SET_IS_RECURRING_SERVICES_LOADING(state, val) {
      state.isRecurringServicesLoading = val
    },
    SET_IS_MOST_REQUESTED_RECURRING_SERVICES_LOADING(state, val) {
      state.isMostRequestedRecurringServicesLoading = val
    },
    SET_IS_MOST_REQUESTED_SERVICES_LOADING(state, val) {
      state.isMostRequestedServicesLoading = val
    },
    
    SET_IS_EMPLOYEES_LOADING(state, val) {
      state.isEmployeesLoading = val
    },
    SET_IS_PAYED_EMPLOYEES_LOADING(state, val) {
      state.isPayedEmployeesLoading = val
    },
    SET_IS_UNPAYED_EMPLOYEES_LOADING(state, val) {
      state.isUnpayedEmployeesLoading = val
    },
    SET_IS_UNPAYED_PROS_LOADING(state, val) {
      state.isUnpayedProsLoading = val
    },

    SET_IS_BUSINESS_MANAGERS_LIST_LOADING(state, val) {
      state.isBusinessManagersListLoading = val
    },

    SET_IS_BUSINESS_DEVELOPER_LIST_LOADING(state, val) {
      state.isBusinessDeveloperListLoading = val
    },

    SET_IS_ASSISTANT_RH_LIST_LOADING(state, val) {
      state.isAssistantRHListLoading = val
    },

    SET_IS_ADMIN_ORDER_LIST_LOADING(state, val) {
      state.isAdminOrderListLoading = val
    },

    SET_IS_POINTS_FOCAUX_LIST_LOADING(state, val) {
      state.isPointsFocauxListLoading = val
    },

    SET_IS_NOTIFICATION_LIST_LOADING(state, val) {
      state.isNotificationListLoading = val
    },

    SET_IS_NOT_COMPLETED_DIRECT_ORDERS_LOADING(state, val) {
      state.isNotCompletedDirectOrdersLoading = val
    },

    SET_ARCHIVED_DIRECT_ORDERS_LOADING(state, val) {
      state.isArchivedDirectOrdersLoading = val
    },

    SET_ARCHIVED_BUSINESS_ORDERS_LOADING(state, val) {
      state.isArchivedBusinessOrdersLoading = val
    },

    SET_IS_UNFORMED_EMPLOYEES_LOADING(state, val) {
      state.isUnformedEmployeesLoading = val
    },

    SET_IS_DIRECT_ORDERS_HAVE_PROPOSAL_LOADING(state, val) {
      state.isDirectOrdersHaveProposalLoading = val
    },

    SET_POINTS_FOCAUX_LIST(state, val) {
      state.pointsFocauxList = val
    },

    SET_IS_REFERRED_CUSTOMERS_LOADING(state, val) {
      state.isReferredCustomersLoading = val
    },

    SET_IS_PAYED_PROS_LOADING(state, val) {
      state.isPayedProsLoading = val
    },

    SET_IS_NOT_COMPLETED_DIRECT_ORDERS(state, val) {
      state.isNotCompletedDirectOrdersLoading = val
    },

    SET_IS_DIRECT_ORDERS_HAVE_NOT_PROPOSAL_LOADING(state, val) {
      state.isDirectOrdersHaveNotProposalLoading = val
    },
    SET_IS_DIRECT_ORDERS_WHO_NEED_RECOMMANDATION_LOADING(state, val) {
      state.isDirectOrdersWhoNeedRecommandationLoading = val
    },

    // Direct Mutations
    SET_IS_DIRECT_ORDERS_LOADING(state, val) {
      state.isDirectOrdersLoading = val
    },
    SET_IS_BUSINESS_ORDERS_LOADING(state, val) {
      state.isBusinessOrdersLoading = val
    },
    SET_IS_BUSINESS_ORDERS_IN_PACKAGE_LOADING(state, val) {
      state.isBusinessOrdersInPackageLoading = val
    },
    //DIRECT PACKAGES LOADING
    SET_IS_DIRECT_PACKAGES_LOADING(state, val) {
      state.isDirectPackagesLoading = val
    },
    SET_IS_DIRECT_PACKAGE_ORDERS_LOADING(state, val) {
      state.isDirectPackageOrdersLoading = val
    },
    SET_IS_BUSINESS_PACKAGE_ORDERS_LOADING(state, val) {
      state.isBusinessPackageOrdersLoading = val
    },
    SET_IS_TRANSACTIONS_LOADING(state, val) {
      state.isTransactionsLoading = val
    },
     SET_IS_CLIENT_BY_PORTEFEUILLE_LOADING(state, val) {
      state.isClientByPortefeuilleLoading = val
    },
    SET_IS_ACTIVITY_LOADING(state, val) {
      state.isActivityLoading = val
    },
    SET_IS_CUSTOMERS_MEET_LOADING(state, val) {
      state.isCustomersMeetLoading = val
    },
  },
  

  actions: {
    async getS3FileTemporaryUrlAction({ commit }, key) {
      const response = await $service.getS3FileTemporaryUrlService(key)
      return response.data
    },
  },

  modules: {
    auth,
    users,
    app,
    access,
    services,
    categories,
    orders,
    professionals,
    customers,
    statistics,
    roles,
    offerType,
    appConfig,
    verticalMenu,
    suivis,
    formation,
    blog,
    packages
  },

  strict: process.env.DEV,
})
