/* eslint-disable no-unused-vars */
// eslint-disable-next-line import/no-cycle
import $service from '@/callApi/roles'

export default {
  namespaced: true,

  state: {
    businessManagersList: [],
    commercialList: [],
    rHList: [],
    businessDevelopersList: [],
    adminOrderList: [],

  },

  getters: {
    getBusinessManagersList: state => state.businessManagersList,
    getCommercialList: state => state.commercialList,
    getAssistantRHList: state => state.assistantRHList,
    getrHList: state => state.rHList,
    getBusinessDeveloperList: state => state.businessDevelopersList,
    getAdminOrderList: state => state.adminOrderList,
    

  },

  mutations: {
    SET_BUSINESS_MANAGERS_LIST(state, val) {
      state.businessManagersList = val
    },
    SET_COMMERCIAL_LIST(state, val) {
      state.commercialList = val
    },
     SET_ASSISTANT_RH_LIST(state, val) {
      state.assistantRHList = val
    },
    SET_RH_LIST(state, val) {
      state.rHList = val
    },
    SET_BUSINESS_DEVELOPERS_LIST(state, val) {
      state.businessDevelopersList = val
    },
    SET_ADMIN_ORDER_LIST(state, val) {
      state.adminOrderList = val
    },
  },

  actions: {
    async getBusinessManagersListAction({ commit }) {
      const response = await $service.getBusinessManagersListServices()
      commit('SET_BUSINESS_MANAGERS_LIST', response.data.data)
      return response.data
    },

    async getCommercialListAction({ commit }) {
      const response = await $service.getCommercialListServices()
      commit('SET_COMMERCIAL_LIST', response.data.data)
      return response.data
    },
     async getAssistantRHListAction({ commit }) {
      const response = await $service.getAssistantRHListServices()
      commit('SET_ASSISTANT_RH_LIST', response.data.data)
      return response.data
    },
    async getRHListAction({ commit }) {
      const response = await $service.getRHListServices()
      commit('SET_RH_LIST', response.data.data)
      return response.data
    },

    async getBusinessDeveloperListAction({ commit }) {
      const response = await $service.getBusinessDeveloppersListServices()
      commit('SET_BUSINESS_DEVELOPERS_LIST', response.data.data)
      return response.data
    },

    async getAdminOrderListAction({ commit }) {
      const response = await $service.getAdminOrderListServices()
      commit('SET_ADMIN_ORDER_LIST', response.data.data)
      return response.data
    },

     async getActifEmployeesAction({ commit }, {packageId}) {
      const response = await $service.getActifEmployeesServices({packageId})
      return response.data
    },


  },
}
