/* eslint-disable no-plusplus */
/* eslint-disable import/no-cycle */
import { objectToFormData } from '@/@core/utils/utils'
import $http from '@/libs/axios'

export default {
  getBestProfessionalsService() {
    return $http.get('/professionals/best')
  },
  getProfessionalsService() {
    return $http.get('/professionals')
  },
  getCautionLinkService() {
    return $http.get('/caution/link')
  },
  filterProfessionalsService(payload) {
    const data = objectToFormData(payload)
    return $http.post('/professionals', data)
  },
  getMoreProfessionalsService(url) {
    return $http.get(`${url}`)
  },
  getMoreEmployeesService(url) {
    return $http.get(`${url}`)
  },
  getMoreEmployeesWalletLogsService(url) {
    return $http.get(`${url}`)
  },
  getProfessionalsMetaDataService(id) {
    return $http.get(`/professionals/${id}/meta/data`)
  },
  getEmployeesWithoutPaginationService() {
    return $http.get("/employees/without/pagination")
  },
  getProfessionalsWithoutPaginationService() {
    return $http.get("/professionals/without/pagination")
  },
  employeeSalaryUnpaidService(employeeId) {
    return $http.get(`/employees/${employeeId}/un/paid/salary`)
  },

  /* assignProRegistrationService({ proId, payload }) {
    const data = objectToFormData(payload)
    return $http.post(`admin/professionals/${proId}/assign/registration`, data)
  }, */
  signContratService({ propositionId, payload }) {
    const data = objectToFormData(payload)
    return $http.post(`/propositions/${propositionId}/approuve/employee/contrat`, data)
  },
  signBusinessContratService({ orderId, employeeId, payload }) {
    const data = objectToFormData(payload)
    return $http.post(`business/recurring/orders/${orderId}/approve/employee/${employeeId}/contract`, data)
  },

  getProfessionalsCommentsService(id) {
    return $http.get(`/professionals/${id}/comments`)
  },

  getProfessionalsDetailsService(id) {
    return $http.get(`/professionals/${id}/details`)
  },
  getEmployeesDetailsService(id) {
    return $http.get(`/employees/${id}`)
  },

  registerProfessionalService(payload) {
    const data = objectToFormData(payload)
    data.delete('services_id')
    data.delete('descriptions')
    data.delete('works_picture')
    payload.services_id.forEach(item => data.append('services_id[]', item))
    payload.descriptions.forEach(item => data.append('descriptions[]', item))
    for (let i = 0; i < payload.works_picture.length; i++) {
      const item = payload.works_picture[i]
      for (let j = 0; j < item.length; j++) {
        const element = item[j]
        data.append(`works_picture[${i}][${j}]`, element)
      }
    }

    return $http.post('/professionals/register', data)
  },

  updateProfessionalService(payload, id) {
    const data = objectToFormData(payload)
    data.delete('services_id')
    data.delete('descriptions')
    data.delete('works_picture')
    payload.services_id.forEach(item => data.append('services_id[]', item))
    payload.descriptions.forEach(item => data.append('descriptions[]', item))
    payload.works_picture.forEach(item => item.forEach(element => {
      data.append('works_picture[][]', element)
    }))

    return $http.post(`/professionals/${id}/update/pro/services`, data)
  },

  filterEmployeesService(payload) {
    const data = objectToFormData(payload)
    return $http.post('/employees', data)
  },

   /* deleteProfessionalService(id) {
    return $http.delete(`/admin/professionals/${id}`)
  }, */

  /* getProPerServiceAndAddressService() {
    return $http.get('/admin/statistics/professional/per/service/and/address')
  }, */
  suspendEmployeeService(payload,empId) {
    const data = objectToFormData(payload)
    return $http.post(`/employees/${empId}/suspend`, data)
  },
  unsuspendEmployeeService(empId) {
    return $http.get(`/employees/${empId}/unsuspend`)
  },
  downloadEmployeeContractService(id) {
    return $http.get(`/employees/${id}/contract`)
  },

  validateProService(id) {
    return $http.get(`/professionals/${id}/accept`)
  },
  validateCandidatureProService(id) {
    return $http.get(`/professionals/${id}/validate/candidature`)
  },

  suspendProService(id) {
    return $http.get(`/professionals/${id}/suspend`)
  },

  unSuspendProService(id) {
    return $http.get(`/professionals/${id}/unsuspend`)
  },

  // Direct API CALLS
  getEmployeesService() {
    return $http.get('/employees')
  },

  getMoreEmployeesWalletService(url) {
    return $http.get(`${url}`)
  },

  getEmployeesWalletService() {
    return $http.get('/employees/wallets')
  },

  getEmployeesWalletsLogsService() {
    return $http.get('/employees/wallets/logs')
  },
  filterEmployeesWalletsLogsService(payload) {
    const data = objectToFormData(payload)
    return $http.post('/employees/wallets/logs',data)
  },

  filterEmployeesWalletService(payload) {
    const data = objectToFormData(payload)
    return $http.post('/employees/wallets', data)
  },
  getEmployeesWalletDetailsService(id) {
    return $http.get(`/employees/wallets/${id}`)
  },

  getEmployeesWalletLogsService(id) {
    return $http.get(`/employees/wallets/${id}/logs`)
  },

  employeeWalletOperationService({ id, payload }) {
    const data = objectToFormData(payload)
    return $http.post(`/employees/${id}/wallet/operation`, data)
  },
  employeeTransfertOperationService({ id, payload }) {
    const data = objectToFormData(payload)
    return $http.post(`/employees/transfer/salary`, data)
  },

  getBdpSavedEmployeesService(id) {
    return $http.get(`/employees/saved/by/bdp/${id}`)
  },

  getBdpSavedProsService(id) {
    return $http.get(`/professionals/saved/by/bdp/${id}`)
  },

  registerEmployeeService(payload) {
    const data = objectToFormData(payload)
    data.delete('recurring_service_id')
    data.delete('about')
    data.delete('pictures')
    data.delete('years_of_experience')
    data.delete('proof_files')
    data.delete('salary_pretention')
    
    payload.proof_files.forEach(item => data.append('proof_files[]', item))
    payload.salary_pretention.forEach(item => data.append('salary_pretention[]', item))

    payload.recurring_service_id.forEach(item => data.append('recurring_service_id[]', item))
    payload.about.forEach(item => data.append('about[]', item))
    payload.years_of_experience.forEach(item => data.append('years_of_experience[]', item))
    for (let i = 0; i < payload.pictures.length; i++) {
      const item = payload.pictures[i]
      for (let j = 0; j < item.length; j++) {
        const element = item[j]
        data.append(`pictures[${i}][${j}]`, element)
      }
    }
    return $http.post('/employees/register', data)
  },

  addEmployeeServiceService(employeeId, payload) {
    const data = objectToFormData(payload)
    data.delete('recurring_service_id')
    data.delete('about')
    data.delete('pictures')
    data.delete('years_of_experience')
    data.delete('salary_pretention')

    payload.salary_pretention.forEach(item => data.append('salary_pretention[]', item))

    payload.recurring_service_id.forEach(item => data.append('recurring_service_id[]', item))
    payload.about.forEach(item => data.append('about[]', item))
    payload.years_of_experience.forEach(item => data.append('years_of_experience[]', item))
    for (let i = 0; i < payload.pictures.length; i++) {
      const item = payload.pictures[i]
      for (let j = 0; j < item.length; j++) {
        const element = item[j]
        data.append(`pictures[${i}][${j}]`, element)
      }
    }
    return $http.post(`/employees/${employeeId}/add/services`, data)
  },
  validateEmployeeService(id) {
    return $http.get(`/employees/${id}/accept`)
  }, 

  validateEmployeeCandidatureService(id) {
    return $http.get(`/employees/${id}/validate/candidature`)
  },
  deleteEmployeeService(id) {
    return $http.delete(`/employees/${id}`)
  },

  deleteServiceEmployeeService(employeeId,serviceId ) {
    return $http.delete(`/employees/${employeeId}/service/${serviceId}/delete`)
  },

  updateEmployeeService(payload, employeeId, employeeRecurringService) {
    const data = objectToFormData(payload)
    data.delete('pictures')

    payload.pictures.forEach(item => data.append('pictures[]', item))
  
    return $http.post(`/employees/${employeeId}/service/${employeeRecurringService}/update`, data)
    
  },

  /* confirmEmployeesSalaryPaymentService(payload) {
    const data = objectToFormData(payload)
    data.delete('employees_ids')
    payload.employees_ids.forEach(item => data.append('employees_ids[]', item))
    return $http.post('/admin/direct/confirm/employees/have/received/their/salary', data)
  },

  getPayedProsService() {
    return $http.get('/admin/professionals/who/were/paid/for/their/service')
  },

  getUnpayedProsService() {
    return $http.get('/admin/professionals/who/have/not/yet/been/paid/for/their/service')
  },

  confirmProsPaymentService(payload) {
    const data = objectToFormData(payload)
    data.delete('orders_ids')
    payload.orders_ids.forEach(item => data.append('orders_ids[]', item))
    return $http.post('/admin/professionals/confirm/their/have/received/their/payment', data)
  }, */
  confirmPlacementCostPaidService(id) {
    return $http.get(`/packages/${id}/after/placement/fee/payment/0`)
  },
  confirmSalaryPaiementService({ id, directSalaryPaiementId, paymentMethod }) {
    return $http.get(`direct/orders/${id}/after/salary/payment/${directSalaryPaiementId}/${paymentMethod}`)
  },
  blockSalaryPaymentService({id}) {
    return $http.get(`/payments/${id}/block`)
  },
  unBlockSalaryPaymentService({id}) {
    return $http.get(`/payments/${id}/un/block`)
  },
  deleteEmployeeRecommendationService({ employeeRecommendationId }) {
    return $http.delete(`/recommandations/${employeeRecommendationId}`)
  },

  deleteEmployeeAffectationService({ employeeAffectationId }) {
    return $http.delete(`/employees/assigned/${employeeAffectationId}`)
  },

  sendEmployeeInFormationService({employeeId, payload}) {
    return $http.post(`/employees/${employeeId}/send/in/formation`,payload)
  },
   getEmployeeNoteServices({employeeId, serviceId}) {
    return $http.get(`/employees/${employeeId}/service/${serviceId}/notes`)
  },
   getActifEmployeesServices({packageId}) {
    return $http.get(`packages/${packageId}/propositions/actifs`)
    
  },
  rejectEmployeeService({ employeeId, payload }) {
    const data = objectToFormData(payload)
    return $http.post(
      `/employees/${employeeId}/reject/validation`,
      data,
    )
  },
  getIndicesService(){
    return $http.get('/indices')
  },
  addIndicesService(payload){
    const data = objectToFormData(payload)
    return $http.post('/indices', data)
  }
}
