/* eslint-disable no-unused-vars */
/* eslint-disable import/no-cycle */
import { objectToFormData } from '@/@core/utils/utils'
import $http from '@/libs/axios'

export default {
  advancePaymentActionService({ payload, employeeId }) {
    const data = objectToFormData(payload)
    return $http.post(
      `/employees/${employeeId}/received/advance/salary`,
      data,
    )
  },

  filterCustomersService(payload) {
    const data = objectToFormData(payload)
    return $http.post('/users', data)
  },

  getCustomersService() {
    return $http.get('/users')
  },

  getCustomersWithoutPaginationService() {
    return $http.get('/users/without/pagination')
  },

  getCustomersEmployeesWithoutPaginationService(serviceId) {
    return $http.get(`/employees/from/customer/services/${serviceId}`)
  },

  getCustomerByIdService(id) {
    return $http.get(`/users/${id}`)
  },
  updateCustomerInfoService(user,payload) {
  /*   const data = objectToFormData(payload) */
    console.log("Data user", payload)
    return $http.post(`/users/update/${user}`,payload)
  },

  withdrawInCustomerWalletService(walletId,payload) {
      return $http.post(`/users/withdraw/${walletId}`,payload)
    },
  

  getMoreCustomersService(url) {
    return $http.get(`${url}`)
  },

  getCustomerWalletService(id) {
    return $http.get(`/users/${id}/wallet`)
  },

  getCustomerOrdersService(id) {
    return $http.get(`/admin/customers/${id}/orders`)
  }, 

  getCustomerDirectOrdersService(id) {
    return $http.get(`/admin/direct/customers/${id}/orders`)
  }, 

  deleteCustomerService(id) {
    return $http.delete(`/users/${id}`)
  },

  addNewCustomerService(payload) {
    const data = objectToFormData(payload)
    return $http.post('/users/new', data)
  },

  /* getCustomersInDeptService() {
    return $http.get(
      'admin/direct/customers/who/have/not/yet/paid/this/month/salary',
    )
  },

  deleteReferredCustomersService(id) {
    return $http.delete(`/admin/direct/customers/referred/${id}`)
  }, */
}
