import { objectToFormData } from '@/@core/utils/utils'
// eslint-disable-next-line import/no-cycle
import $http from '@/libs/axios'

export default {
    getDirectPackagesService(){
        return $http.get('/packages')
      },
    getDirectPackageOrdersService(id){
        return $http.get(`/packages/${id}/recurring/orders`)
    },
    createNewPackageOrderService(payload){
      return $http.post('/recurring/orders', payload)
    },
    
  makePackageOrderForAClientService({ userId, payload }) {
    const data = objectToFormData(payload)
    return $http.post(
      `/admin/direct/customers/${userId}/new/direct/orders`,
      data,
    )
  },
   
 
  getPackagesByIdService(packageId) {
     return $http.get(`/packages/${packageId}`)
  },
  getDirectOrderService(id) {
    return $http.get(`/recurring/orders/${id}`)
  },
   sendFeedbackService({ orderId, payload }) {
    
    return $http.post(`/recurring/orders/${orderId}/send/feedback`, payload)
  },
  getFeedbackAnswersService(id) {
    return $http.get(`/recurring/orders/${id}/feedback/answers`)
  },
  makePropositionService(payload) {
    return $http.post('/propositions', payload)
  },
  getRecommendedEmployeesService({id}) {
    return $http.get(`/recurring/services/${id}/formed/employees`)
  },
  
   getSalaryDetailsService(propositionId) {
    return $http.get(`/propositions/${propositionId}/details/salary`)
  },
  getRecommendationEmployeesService(payload) {
    const data = objectToFormData(payload)
    return $http.post('/recommandations', data)
  },
  modifyDirectOrderService({ id, payload }) {
    const data = objectToFormData(payload)
    return $http.post(`/recurring/orders/${id}/update`, data)
  },
  getDirectOrderProposedEmployeesService(payload) {
     const data = objectToFormData(payload)
    return $http.post('/propositions/send', data)
  },
   deleteDirectOrderPropositionService(propositionId) {
    return $http.delete(
      `/propositions/${propositionId}`,
    )
  },

  assignAPackageToBusinessManagerService({ packageId, payload }) {
    const data = objectToFormData(payload)
    return $http.post(`/packages/${packageId}/assign`, data)
  },
  autoAssignmentService({ packageId, payload }) {
    const data = objectToFormData(payload)
    return $http.post(`/packages/${packageId}/assign`, data)
  },

  validateExonarationService(orderId){
    return $http.post(`/recurring/orders/${orderId}/approve/exoneration`)
  },
   
  filterDirectOrderService({payload, id}) {
    const data = objectToFormData(payload)
    return $http.post(`/packages/${id}/recurring/orders`, data)
  },
     assignAPackageToAssistantRHService({ packageId, payload }) {
    const data = objectToFormData(payload)
       return $http.post(`/packages/${packageId}/assign/to/rh`, data)
       
  },
  autoAssignmentRHService({ packageId, payload }) {
    const data = objectToFormData(payload)
       return $http.post(`/packages/${packageId}/assign/to/rh`, data)
       
  },
     
   filterPackagesService(payload) {
    const data = objectToFormData(payload)
    return $http.post('/packages', data)
  },
  filterPackagesCommandesService(payload) {
    const data = objectToFormData(payload)
    return $http.post('/packages/filter/by/status', data)
  },
   getMorePackagesService(url) {
    return $http.get(`${url}`)
  },
   getMoreOrderService(url) {
    return $http.get(`${url}`)
  },
  

}