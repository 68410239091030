// import { objectToFormData } from '@/@core/utils/utils'
// eslint-disable-next-line import/no-cycle
import $http from '@/libs/axios'

export default {
  getBusinessManagersListServices() {
    return $http.get('/users/ca')
  },
  getCommercialListServices() {
    return $http.get('/users/commercials')
  },
   getAssistantRHListServices() {
    return $http.get('/users/arh')
  },
  getRHListServices() {
    return $http.get('/users/recruiters')
  },
  /* getBusinessDeveloppersListServices() {
    return $http.get('/admin/users/bdp')
  },
  getAdminOrderListServices() {
    return $http.get('/admin/users/cc')
  }, */
  getActifEmployeesServices({packageId}) {
    return $http.get(`packages/${packageId}/propositions/actifs`)
    
  }
}

