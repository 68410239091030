/* eslint-disable no-unused-vars */
/* eslint-disable import/no-cycle */
import $service from '@/callApi/auth'

export default {
  namespaced: true,
  state: {
    user: null,
    token: false,
  },
  getters: {
    getUser: state => state.user,
  },
  mutations: {
    SET_USER(state, val) {
      state.user = val
    },
    SET_TOKEN(state, val) {
      state.token = val
    },
  },
  actions: {
    async loginAction({ commit }, payload) {
      const response = await $service.loginService(payload)
      if (response && response.data.data.role.id !== 1) {
        localStorage.setItem('token', response.data.access_token)
        localStorage.setItem('user', JSON.stringify(response.data.data))
        commit('SET_USER', response.data.data)
        commit('SET_TOKEN', response.data.access_token)
      }
      return response.data
    },
    async changeProfileImageAction({ commit }, payload) {
      const response = await $service.changeProfileImageService(payload)
      commit('SET_USER', response.data.data)
      localStorage.setItem('user', JSON.stringify(response.data.data))
      return response.data
    },
    async changeProProfileImageAction({ commit }, payload) {
      const response = await $service.changeProProfileImageService(payload)
      return response.data
    },
    async passwordForgotAction({ commit }, payload) {
      const response = await $service.passwordForgotService(payload)
      return response.data
    },
    async resetPasswordAction({ commit }, payload) {
      const response = await $service.resetPasswordService(payload)
      return response.data
    },
    async changePasswordAction({ dispatch }, payload) {
      const response = await $service.changePasswordService(payload)
      dispatch('removeToken')
      return response.data
    },
    async logoutAction({ dispatch }) {
      const response = await $service.logoutService()
      dispatch('removeToken')
      return response
    },
    async changeProfileInfoAction({ commit }, payload) {
      const response = await $service.changeProfileInfoService(payload)
      commit('SET_USER', response.data.data)
      localStorage.setItem('user', JSON.stringify(response.data.data))
      return response.data
    },
    async changeProProfileInfoAction({ commit }, payload) {
      const response = await $service.changeProProfileInfoService(payload)
      return response.data
    },
    removeToken({ commit }) {
      localStorage.clear()
      commit('SET_TOKEN', null)
    },

    // Direct Actions
    async changeEmployeeProfileImageAction({ commit }, payload) {
      const response = await $service.changeEmployeeProfileImageService(payload)
      return response.data
    },

    async changeEmployeeProfileInfoAction({ commit }, payload) {
      const response = await $service.changeEmployeeProfileInfoService(payload)
      return response.data
    },
  },
}
