import Vue from 'vue'
import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'
import JsonExcel from 'vue-json-excel'
import NotAuthorized from "@/layouts/components/NotAuthorized";
import BootstrapVueTimeline from 'bootstrap-vue-timeline'

Vue.component(FeatherIcon.name, FeatherIcon)

Vue.component('downloadExcel', JsonExcel)
Vue.component('NotAuthorized', NotAuthorized)
Vue.component('b-timeline', BootstrapVueTimeline)

