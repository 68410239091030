import { objectToFormData } from '@/@core/utils/utils'
import $http from '@/libs/axios'

export default {
  getRolesService() {
    return $http.get('/role')
  },
  addNewRoleService(payload) {
    const data = objectToFormData(payload)
    return $http.post('/role', data)
  },
  deleteRoleService(id) {
    return $http.delete(`/role/${id}`)
  },
  updateRoleService(payload, id) {
    return $http.post(`/role/${id}`, payload)
  },

}
