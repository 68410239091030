/* eslint-disable no-unused-vars */
// eslint-disable-next-line import/no-cycle
import $service from '@/callApi/packages'

export default {
  namespaced: true,
  state: {
    directPackages: [],
    directPackageOrders: [],
    newPackageOrder: null,
    client: null,
    directOrder: null,
    currentPackage: null,
    currentClient: null,
    currentPage : null,
    payloadFilter : {},
    // currentPackageowner: null,
  },
  getters: {
    getCurrentPage : (state) => state.currentPage,
    getPayloadFilter : (state) => state.payloadFilter,
    getCurrentPackage: (state) => state.currentPackage,
    getCurrentClient: (state) => state.currentClient,
    getDirectPackages: (state) => state.directPackages,
    getDirectPackageOrders: (state) => state.directPackageOrders,
    getNewPackageOrder: (state) => state.newPackageOrder,
    getDirectOrder: state => state.directOrder,
    getClient: (state) => state.client,
    // getcurrentPackageowner : state => state.currentPackageowner
  },
  mutations: {
    SET_CURRENT_PACKAGE(state, val) {
      state.currentPackage = val
    },
    SET_PAYLOAD_FILTER(state, val) {
      state.payloadFilter = val
    },
    SET_NEW_PACKAGE_ORDERS(state, val) {
      state.newPackageOrder = val
    },
     SET_CURRENT_CLIENT(state, val) {
      state.currentClient = val
    },
     SET_DIRECT_ORDER(state, val) {
      state.directOrder = val
    },
    SET_DIRECT_PACKAGES(state, val) {
      state.directPackages = val
    },
    SET_DIRECT_PACKAGE_ORDERS(state, val) {
      state.directPackageOrders = val
    },
    SET_NEW_PACKAGE_ORDER(state, val) {
      state.newPackageOrder = val
    },
    SET_CLIENT(state, val) {
      state.client = val
    },
    // SET_CURRENT_PACKAGE_OWNER(state, val){
    //     state.client = val
    // }
  },
  actions: {
    savePayloadFilter({ commit }, payloadFilter) {
      commit('SET_PAYLOAD_FILTER', payloadFilter);
    },
    //la fonction prends en parametre un package loaclastorage creer une fonction @click qui va se charger de faire les deux fonctions
    async getDirectPackagesAction({ commit }) {
      const response = await $service.getDirectPackagesService()
      commit('SET_DIRECT_PACKAGES', response.data.data)
      return response.data
    },
    async getDirectPackageOrdersAction({ commit }, id) {
      const response = await $service.getDirectPackageOrdersService(id)
      console.log(response)
      commit('SET_DIRECT_PACKAGE_ORDERS', response.data.data)
      return response.data
    },
    async newPackageOrderAction({ commit }, payload) {
      const response = await $service.createNewPackageOrderService(payload)
      commit('SET_CURRENT_PACKAGE', response.data.data)
      return response.data
    },
    async makePackageOrderForAClientAction({ commit }, { userId, payload }) {
      const response = await $service.makePackageOrderForAClientService({
        userId,
        payload,
      })
      return response.data
    },
   
   
    async getPackageByIdAction({ commit }, packageId) {
      const response = await $service.getPackagesByIdService(packageId)
      commit('SET_CURRENT_PACKAGE', response.data.data)
      return response.data
    },
    async getDirectOrderAction({ commit }, id) {
      const response = await $service.getDirectOrderService(id)
      commit('SET_DIRECT_ORDERS', response.data.data)
      return response.data
    },
    async sendFeedbackAction({ commit }, { orderId, payload }) {
      const response = await $service.sendFeedbackService({ orderId, payload })
      return response.data
    },
    async getFeedbackAnswersAction({ commit }, id) {
      const response = await $service.getFeedbackAnswersService(id)
      return response.data
    },
    async makePropositionAction({ commit }, payload) {
      const response = await $service.makePropositionService(payload)
      console.log(response)
      return response.data
      
    },
    async getRecommendedEmployeesAction({ commit }, { id }) {
      const response = await $service.getRecommendedEmployeesService({ id })
      return response.data
    },
    async modifyDirectOrderAction({ commit }, { id, payload }) {
      const response = await $service.modifyDirectOrderService({ id, payload })
      commit('SET_DIRECT_ORDER', response.data.data)
      return response.data
      },
    async getDirectOrderProposedEmployeesAction({ commit },payload) {
      const response = await $service.getDirectOrderProposedEmployeesService(
        payload
      )
   
      return response.data
    },

    async assignAPackageToBusinessManagerAction(
      { commit },
      { packageId, payload },
    ) {
      const response = await $service.assignAPackageToBusinessManagerService({
        packageId,
        payload,
      })
      commit('SET_CURRENT_PACKAGE', response.data.data)
      console.log(response.data.data)
      return  response.data
    },
    async autoAssignmentAction({ commit },{ packageId, payload },) {
      const response = await $service.autoAssignmentService({packageId,payload})
      commit('SET_CURRENT_PACKAGE', response.data.data)
      console.log(response.data.data)
      return  response.data
    },

    async getRecommendationEmployeesAction({ commit }, payload) {
      const response = await $service.getRecommendationEmployeesService(
        payload
      )
      return response.data    
    },
    async deleteDirectOrderPropositionAction(
      { commit },propositionId
    ) {
      const response = await $service.deleteDirectOrderPropositionService(propositionId)
      return response
    },

    async validateExonerationAction({ commit }, orderId ){
      const response = await $service.validateExonarationService(orderId)
      return response
    },

    async filterDirectOrderAction({ commit }, {payload, id}) {
      const response = await $service.filterDirectOrderService({payload, id})
      commit('SET_DIRECT_ORDERS', response.data.data)
      return response.data
    },
    async getSalaryDetailsAction({ commit }, propositionId) {
      const response = await $service.getSalaryDetailsService(
        propositionId
      )
      return response.data    
    },
    async assignAPackageToAssistantRHAction(
      { commit },
      { packageId, payload },
    ) {
      return await $service.assignAPackageToAssistantRHService({
        packageId,
        payload,
      })
    },

    async autoAssignmentRHAction(
      { commit },
      { packageId, payload },
    ) {
      return await $service.autoAssignmentRHService({
        packageId,
        payload,
      })
    },

    async filterPackagesAction({ commit }, payload) {
      const response = await $service.filterPackagesService(payload)
      commit('SET_DIRECT_PACKAGES', response.data.data)
      return response.data
    },
    async filterPackagesCommandesAction({ commit }, payload) {
      const response = await $service.filterPackagesCommandesService(payload)
      commit('SET_DIRECT_PACKAGES', response.data.data)
      return response.data
    },
     async loadMoreDirectPackagesAction({ commit }, url) {
      const response = await $service.getMorePackagesService(url)
      commit('SET_DIRECT_PACKAGES', response.data.data)
      return response.data
    },
     async loadMoreDirectOrderAction({ commit }, url) {
      const response = await $service.getMoreOrderService(url)
      commit('SET_DIRECT_PACKAGE_ORDERS', response.data.data)
      return response.data
    },
  },
}
