import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import { localize } from 'vee-validate'
import fr from 'vee-validate/dist/locale/fr.json'
import en from 'vee-validate/dist/locale/en.json'
import '../node_modules/timeline-vuejs/dist/timeline-vuejs.css'
import '@/assets/scss/custom.scss';

import moment from 'moment'
import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import LightTimeline from 'vue-light-timeline';
import VueSignaturePad from 'vue-signature-pad';

Vue.use(VueSignaturePad);

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

Vue.prototype.moment = moment
Vue.use(LightTimeline);
// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

localize({
  fr,
  en,
})
localize('fr')

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
